import {useState, useCallback} from 'react'
import { useDropzone } from "react-dropzone";
import { Box, Card, CardContent, CardHeader, Button, Stack, Typography, Alert } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { migrateBudgetInfo } from "../Common/stateSlices/budgetStateSlice";
import { tabTypes } from '../../app/models';
import { setMigrationCurrentTab, selectMigrationCurrentTab, addMigrationTab } from '../Common/stateSlices/appStateSlice';




function MigrationDropzone({ accept, open }) {
    const dispatch = useAppDispatch();
    const currentTab = useAppSelector(selectMigrationCurrentTab)

    const [myFiles, setMyFiles] = useState([])
    const [fileError, setError] = useState("")

    const onDrop = useCallback(acceptedFiles => {
        if(acceptedFiles[0].type === "application/json"){
            console.log(acceptedFiles)
            setMyFiles([...myFiles, ...acceptedFiles])
        } else{
            setError("This is not a JSON file")
        }

      }, [myFiles])


  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      onDragEnter: ()=>setError("")
    });

    const removeAll = () => {
        setMyFiles([])
      }

    function handleSubmit (){
        const file = myFiles[0]
        const reader = new FileReader();
        reader.onload = function(e) {
        const contents = e.target.result;
            console.log(contents);
            const contentString = contents.toString()
            console.log(contents);
            console.log(JSON.parse(contentString))
            dispatch(migrateBudgetInfo(JSON.parse(contentString)))
            };
        reader.readAsText(file)
        dispatch(setMigrationCurrentTab( currentTab +1 ))
        dispatch(addMigrationTab({tab: tabTypes.bbgId, navigate: true}))
    }

    const files = myFiles.map((file) => (
        <li key={file.name}>
        {file.name}
        </li>
    ));

  return (
    <Card>
        <CardHeader title="Start from a JSON file"/>
        <CardContent>
            <aside>
                <ul>{files}</ul>
            </aside>
            {files.length === 0
            ? <Box sx={{textAlign: "center", border: "2px dashed", borderColor: "primary.main", marginBottom: "10px"}} {...getRootProps({ className: "dropzone" })}>
                    <input className="input-zone" {...getInputProps()} />
                        <div className="text-center">
                        {isDragActive ? (
                            <p className="dropzone-content">
                            Release to drop the files here
                            </p>
                        ) : (
                            <p className="dropzone-content">
                            Drag and drop your JSON file, <br/>or click to select files
                            </p>
                        )}
                        </div>
                </Box>
            : <Stack gap={1}>
                <Button variant="contained" onClick={handleSubmit}>Use This File</Button>
                <Button variant="contained" onClick={removeAll}>Use a Different File</Button>
            </Stack>
            }
            {fileError !== ""
                ? <Alert severity="error" >{fileError}</Alert>
                : null
            }
      </CardContent>
    </Card>
  );
}

export default MigrationDropzone;