import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
      primary: {
        light: '#a4987a',
        main: '#756a4e',
        dark: '#493f25',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#856d41',
        main: '#564218',
        dark: '#2d1b00',
        contrastText: '#ffffff',
      },
    },
  });