import React, { useState, useEffect, useRef} from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HelpIcon from "@mui/icons-material/Help";
import Snackbar from "@mui/material/Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from '@mui/material/Link';
import Alert from "@mui/material/Alert";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { budgetTypes } from "../../app/budgetTypes";
import {
  setDefinitionHelpers,
  selectDefinitionHelpers,
  setApplicationSuccessMsg,
  selectApplicationSuccessMsg
} from "../Common//stateSlices/appStateSlice";
import {
  selectRevenueBudgetItemsTotal,
  selectExpenseBudgetItemsTotal,
  selectBudgetItemsCategoryTotal,
  selectBudgetItemsCategorySubTotal,
  selectBudgetNarrative,
  selectTotalYears,
  selectProjectTitle,
  selectBudgetType,
  selectYearsIGAM,
  selectBudgetLoading,
  setNewApplicationInfo, 
  setTotalYears,
  fetchBudgetIdAsync,
  upsertBudgetIdAsync,
  selectSavingStatus,
  selectExpenseTypesAvailable,
  updateProjectData
} from "../Common/stateSlices/budgetStateSlice"
import {
  AvailableBudgetTypes,
  BudgetID,
  DollarType,
  FaqType
} from "../../app/models";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { BudgetTable } from "../budget-table/BudgetTable";
import { ReviewBudgetTable } from "../budget-table-review/ReviewBudgetTable";
import { Print } from "@mui/icons-material";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { BudgetIntroduction } from "./application-connector-programAreas/BudgetIntroduction";
import { ProgramValues } from "../Common/program-area-info/ProgramAreaValues";
import { sendMsgToIGAM, formatDollarValues } from "../Common/helpers";
import { BudgetNarrative } from "../budget-narrative/BudgetNarrative";
import  FaqHelpContents from "./Faq-Help/Faq-Help-Contents";
import { HelpfulDefinition } from "../Common/Definitions/HelpfulDefinitions";
import { igamUrl } from "../../app/constants";
import { printToPDF } from "../Common/pdfPrinting";


const ProgramIdVal = budgetTypes.CFWB1

const yearOptions = ProgramValues(ProgramIdVal).yearOptionsArr.map( yearNum => ({value: yearNum, label: yearNum.toString()}))


function PrintBudgetScreen({handlePrintScreen}){
  const projectTitle = useAppSelector(selectProjectTitle) || "";
  const totalYears = useAppSelector(selectTotalYears);
  const budgetNarrative = useAppSelector(selectBudgetNarrative);
  const printWindowRef = useRef()
  const now = new Date().toLocaleDateString()

  useEffect(()=> {printToPDF(printWindowRef, "ApplicationBudget", handlePrintScreen)})
  return(
    <>
    <Box ref={printWindowRef}>
    <Typography
        variant="h2"
        sx={{marginTop: "76px", marginLeft: "2ch"}}
      >
        The Duke Endowment 
      </Typography>
      
      <Typography
        variant="h4"
        sx={{marginTop: "76px", marginLeft: "2ch"}}
      >
        {projectTitle} budget
      </Typography>
      <br/>
      <br/>
      <Typography variant="caption">Date: {now}</Typography>
      <ReviewBudgetTable years={totalYears} />
      <br/>
      {budgetNarrative 
        ? (
        <>
            <Typography
              variant="h4"
              sx={{ marginTop: "1ch", marginLeft: "2ch" }}
            >
              {projectTitle} - Narrative
            </Typography>
          <Box component={Paper} sx={{ margin: "4ch", minHeight: "200px", paddingX: "20px", paddingY: "10px" }}>
            {budgetNarrative.split('\n').map(txt => <Typography key={txt}>{txt}</Typography>)}
          </Box>
        </>
        )
        : null
      }
      </Box>
    </>
  )
}

function BudgetDetails({handlePrintScreen}) {
  const totalYears = useAppSelector(selectTotalYears);
  const { hasProgramExpenses, hasIndirectExpenses } = useAppSelector(selectExpenseTypesAvailable)
  const yearsIGAM = useAppSelector(selectYearsIGAM)
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const YearsMatchesIGAM = totalYears == yearsIGAM;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "50ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Box sx={{ mx: "auto", width: "calc(100vw*.9)", mb: 1 }}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">Select Number of Years:</FormLabel>
          <TextField
            id="outlined-select-currency"
            sx={{ width: "150px" }}
            required
            select
            defaultValue=""
            value={totalYears || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setTotalYears(Number(event.target.value)));
            }}
          >
            {yearOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {!YearsMatchesIGAM && <Alert severity="warning">You've chosen {totalYears} years, when your application is showing a request for {yearsIGAM} years. Please make sure to update your application as needed.</Alert>}
        </FormControl>
        {/* <Button
          size="large"
          sx={{ float: "right", textTransform: "none"}}
          endIcon={<Print />}
          variant="text"
          onClick={handlePrintScreen}
        >
          Print
        </Button> */}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="PrintScreen"
        aria-describedby="Please use this to print your budget."
      >
        <Box>
          <Typography>
            Budget
          </Typography> 
          <ReviewBudgetTable
            years={totalYears}
            hasProgramExpenses={hasProgramExpenses}
          />
        </Box>
      </Modal>
      <BudgetTable
        years={totalYears}
        hasProgramExpenses={hasProgramExpenses}
      />
    </Box>
  );
}
function ReviewBudgetDetails({handlePrintScreen}) {
  const totalYears = useAppSelector(selectTotalYears);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "50ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Box sx={{ mx: "auto", width: "calc(100vw*.9)" }}>
        <Button
          size="large"
          sx={{ float: "right", textTransform: "none"}}
          endIcon={<Print />}
          variant="text"
          onClick={handlePrintScreen}
        >
          Print
        </Button>
      </Box>
      <ReviewBudgetTable
        years={totalYears}
      />
    </Box>
  );
}

function BudgetSubmit() {
  const {revenue} = BudgetID;
  const totalYears = useAppSelector(selectTotalYears);
  const yearsIGAM = useAppSelector(selectYearsIGAM);
  const budgetItemsCategorySubTotal = useAppSelector(selectBudgetItemsCategorySubTotal)
  const budgetItemsCategoryTotal = useAppSelector(selectBudgetItemsCategoryTotal);
  const ExpenseBudgetItemsTotal = useAppSelector(selectExpenseBudgetItemsTotal);
  const netAmount = budgetItemsCategoryTotal(revenue) - ExpenseBudgetItemsTotal();
  
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "50ch" },
        mx: "auto", 
        width: "calc(100vw*.9)"
      }}
      noValidate
      autoComplete="off"
    >
      <Typography variant="h1" sx={{fontSize:"5ch"}}>Submit Your Budget </Typography>
      <Typography variant="subtitle1">Please review the totals below and add your budget to the application.</Typography>
      <Typography gutterBottom variant="h5" component="div">
        Total Years: <b>{totalYears}</b>
      </Typography>
      {totalYears !== yearsIGAM && 
        <Alert severity="warning" sx={{ width: "600px" }}>You've chosen {totalYears} years, when your application is showing a request for {yearsIGAM} years. Please make sure to update your application as needed.</Alert>
      }
      <Typography gutterBottom variant="h5" component="div">
        Total Revenue: <b>${budgetItemsCategoryTotal(revenue).toLocaleString()}</b>
      </Typography>
      {(budgetItemsCategoryTotal(revenue) === 0) && <Alert severity="error" sx={{ width: "600px" }} >Please add a revenue source in the <b>Create Your Budget</b> screen.</Alert>}
      <Box sx={{ paddingLeft: "30px" }}>
        <Typography gutterBottom variant="h6" component="div">
          Request of The Duke Endowment: ${budgetItemsCategorySubTotal(BudgetID.revenue, DollarType.TDE).toLocaleString()}
        </Typography>
        {(budgetItemsCategorySubTotal(BudgetID.revenue, DollarType.TDE) === 0) && <Alert severity="error" sx={{ width: "600px" }} >There must be a request for The Duke Endowment!</Alert>}
        {/* <Typography gutterBottom variant="h6" component="div">
          Contributions from applicant: <b>${ContributionsTotal().toLocaleString()}</b>
        </Typography>
        {ContributionsTotal() === 0 && <Alert severity="warning" sx={{ width: "600px" }}>Are there any contributions from the applicant?</Alert>} */}
        <Typography gutterBottom variant="h6" component="div">
          Other Sources: <b>${budgetItemsCategorySubTotal(BudgetID.revenue, DollarType.Other).toLocaleString()}</b>
        </Typography>
        {budgetItemsCategorySubTotal(BudgetID.revenue, DollarType.Other) === 0 && <Alert severity="warning" sx={{ width: "600px" }}>Are there any other contributions aside from the Duke Endowment?</Alert>}
      </Box>
      <Typography gutterBottom variant="h5" component="div">
        Total Expenses: <b>${ExpenseBudgetItemsTotal().toLocaleString()}</b>
      </Typography>
      {ExpenseBudgetItemsTotal() === 0 && <Alert severity="error" sx={{ width: "600px" }}> Please add an expense to your budget in the 'Create Your Budget' screen.</Alert>}
      <Typography gutterBottom variant="h5" component="div" sx={{ 
        color:  ( netAmount < 0) ? "red" : "black"
      }}>
        Net Amount: <b>{formatDollarValues(netAmount)}</b>
      </Typography>
      {netAmount < 0 &&
        <Alert severity="warning" sx={{width: "600px"}}>The expenses incurred are greater than the revenue. Please make sure this is correct, and provide an explanation in the <b>Budget Narrative</b> screen.</Alert>
      }
    </Box>
  );
}

export const ApplicationConnector = () => {

  const [activeStep, setActiveStep] = React.useState(0);
  const [savedSnackBarOpen, setSavedSnackBarOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [openAppErr, setOpenAppErr] = React.useState(false);
  const [printScreenOpen, setPrintScreenOpen] = React.useState(false);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const budgetLoadingStatus = useAppSelector(selectBudgetLoading );
  const savingStatus = useAppSelector(selectSavingStatus);
  const RevenueBudgetItemsTotal = useAppSelector(selectRevenueBudgetItemsTotal);
  const budgetItemsCategoryTotal = useAppSelector(selectBudgetItemsCategoryTotal);
  const ExpenseBudgetItemsTotal = useAppSelector(selectExpenseBudgetItemsTotal);
  const BudgetType = useAppSelector(selectBudgetType);
  const DefinitionHelpers = useAppSelector(selectDefinitionHelpers);
  const projectTitle = useAppSelector(selectProjectTitle) || "Development Project";
  const applicationSuccessMsg = useAppSelector(selectApplicationSuccessMsg);
  const { revenue } = BudgetID

  //Post Office - handles incoming messages from IGAM and dispatches to applicationConnectorSlice as needed
  const handleReceiveMsg = (message) => {
    if (message.origin === igamUrl){
      let budgetKey: string;
      console.log(message.data);
      const { projectTitle, totalYears, budgetType }: { projectTitle?: string, totalYears?: string, budgetType?: string, budgetKey?: string } = message.data.payload 
      switch (message.data.key) {
        case "get_project_data":
          budgetKey = message.data.payload.budgetKey;
          dispatch(setNewApplicationInfo({ id, projectTitle, totalYears, budgetType, budgetKey}))
          break;
        case "update_project_data":
          dispatch(updateProjectData({projectTitle, totalYears}))
          break;
        case "get_budgetKey":
          console.log("got budget key", id);
          budgetKey = message.data.payload;
          dispatch(fetchBudgetIdAsync({ id, admin: false, budgetKey }));
          break;
        case 'tde_budget_application_status':
          dispatch(setApplicationSuccessMsg(true));
          break;
        default: console.log(message.data);
      }
    }
  };

  //requests budget key on start (updates on changes to id, only on start).
  // Dispatches fetch on budget ID to the API
  useEffect(() => {
    window.addEventListener('message', message => handleReceiveMsg(message))
    if(budgetLoadingStatus === "idle"){
      console.log('get_budgetKey sent', id)
      sendMsgToIGAM({ key: 'get_budgetKey'})
      if(id === "1234a"){ //TESTING ONLY
        console.log("fetch budget")
        dispatch(setNewApplicationInfo({ id, projectTitle: "my test project", totalYears: "3", budgetType: budgetTypes.CFWB1, budgetKey: "abcd" }))
      }
    };
    window.addEventListener('beforeunload', (event) => {
      if(!applicationSuccessMsg){
        event.preventDefault();
        event.returnValue = '';
      }
    });
    
  }, [id]);
  
  //Runs updates based on request status
  //new requests get project title, total years, and budget type from IGAM
  //newly loaded budgets get project title and total years and check against records
  useEffect(()=>{
    if (budgetLoadingStatus === "new") {
      console.log(budgetLoadingStatus, 'get project data sent', id)
      sendMsgToIGAM({ key: 'get_project_data' })
      if (id === "1234a") {
        dispatch(setNewApplicationInfo({ id, projectTitle: "my test project", totalYears: "3", budgetType: budgetTypes.CFWB1, budgetKey: "abcd" }))
      }
    } else if (budgetLoadingStatus === "loaded"){
      console.log(budgetLoadingStatus, 'update project data sent')
      sendMsgToIGAM({ key: 'update_project_data' })
    }
  }, [budgetLoadingStatus])

  const handleAddToApplication = () => {
    console.log(ExpenseBudgetItemsTotal())
    budgetItemsCategoryTotal(revenue) === 0 || ExpenseBudgetItemsTotal() === 0 
      ? setOpenAppErr(true)
      : dispatch(upsertBudgetIdAsync({ done: true }))
  };

  const handleSaveDraft = (event) => {
    console.log(id)
    dispatch(upsertBudgetIdAsync({ done: false  }));

    setSavedSnackBarOpen(true);
  };


  const handleCloseSavedSnackBar = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    setSavedSnackBarOpen(false);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAppErr = () => {
    setOpenAppErr(false)
  }

  const handleCloseApplicationSuccessMsg = () => {
    dispatch( setApplicationSuccessMsg(false))
  }

  const handleCloseDef = () => {
   dispatch(setDefinitionHelpers({visible: false, key: ""}))
  }

  const handlePrintScreen = () => {
    console.log("before handle print screen", printScreenOpen)
    setPrintScreenOpen(!printScreenOpen)
    console.log("after handle print screen", printScreenOpen)
  }

  const steps = [
    {
      label: "Let's get started",
      content: <BudgetIntroduction id = {BudgetType} />,
    },
    {
      label: "Create your budget",
      content: <BudgetDetails handlePrintScreen={handlePrintScreen} />,
    },
    {
      label: "Budget Narrative",
      content: <BudgetNarrative/>
    },
    {
      label: "Review your budget",
      content: <ReviewBudgetDetails handlePrintScreen={handlePrintScreen} />
    },
    {
      label: "Submit",
      content: <BudgetSubmit />,
    },
  ];

  return (
    <>
      <MuiAppBar position="absolute" sx={{ bgcolor: "white" }} elevation={0}>
        <Toolbar>
          <img
            style={{ height: 60, margin: '8px auto' }}
            src="/logo.png"
            alt="The Duke Endowment"
          />
        </Toolbar>
      </MuiAppBar>
      {!printScreenOpen 
        ?(
        <>
          <Card variant="outlined" sx={{mt: "64px"}}>
            {savingStatus === "saving" ? (
              <LinearProgress />
            ) : (
              <Box sx={{ height: "4px" }} />
            )}
            <CardHeader
              title="The Duke Endowment Budget Portal"
              subheader="Please follow the steps below to complete your budget."
            />
            <CardContent>
              <Box sx={{ width: "100%" }}>
                {/* {(projectTitle === null || !window.opener) && process.env.NODE_ENV !== "development" ? ( */}
                {false ? (
                  <Box sx={{ display: "flex" }}>
                    <Typography gutterBottom variant="subtitle1" component="div">
                      Please open window from your Grant Application Form using the
                      button under the 'Budget' tab.
                      <br />
                      <a href="https://us.grantrequest.com/?sid=576">
                        Grant Request Login
                      </a>
                    </Typography>
                  </Box>
                ) : budgetLoadingStatus !== "loaded" 
                    ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress sx={{ margin: "auto" }} />
                      </Box>
                      ) 
                    : ( 
                        <>
                          <Box sx={{display: "flex", justifyContent: "space-between"}}>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                    Project Title: <b>{projectTitle}</b>
                            </Typography>
                            <Button
                              size="large"
                              sx={{ float: "right"}}
                              endIcon={<SaveRoundedIcon />}
                              variant="text"
                              onClick={handleSaveDraft}
                            >
                              Save
                            </Button>
                          </Box>
                          <Stepper
                            sx={{ marginTop: 2, mb: 2 }}
                            activeStep={activeStep}
                            orientation="horizontal"
                          >
                            {steps.map((step, index) => {
                              const stepProps = {};
                              const labelProps = {};
                              return (
                                <Step key={step.label} {...stepProps}>
                                  <StepLabel {...labelProps}>{step.label}</StepLabel>
                                </Step>
                              );
                            })}
                          </Stepper>
                          {steps[activeStep].content}
                        </>
                )}
                <Stack direction="row" justifyContent="space-between" sx={{ mx: "auto", mb: 2, mt: 4, width: "calc(100vw*.9)" }}>
                  <Button
                    size="large"
                    onClick={handleBack}
                    sx={{
                      mt: 1,
                      mr: 1,
                      display: activeStep === 0 ? "none" : null,
                    }}
                  >
                    Back
                  </Button>
                  <Box>
                    <Button
                      size="large"
                      onClick={handleSaveDraft}
                      disabled={savingStatus === "saving"}
                      sx={{
                        mt: 1,
                        mr: 1,
                        display:
                          activeStep !== steps.length - 1 ? "none" : null,
                      }}
                    >
                      Save Draft
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={handleAddToApplication}
                      disabled={savingStatus === "saving"}
                      sx={{
                        mt: 1,
                        display:
                          activeStep !== steps.length - 1 ? "none" : null,
                      }}
                    >
                      Add to Application
                    </Button>
                  </Box>
                  <Button
                    size="large"
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      mt: 1,
                      alignSelf: "right",
                      float: "right",
                      display:
                        activeStep === steps.length - 1 ? "none" : null,
                    }}
                  >
                    Continue
                  </Button>
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end"
            }}
          >
              <Button
                size="large"
                sx={{ textTransform: "none" }}
                startIcon={<HelpIcon />}
                variant="text"
                onClick={handleClickOpen}
              >
                FAQs and Help
              </Button>
              <Box sx={{display: "flex", marginRight: "10px"}}>
                <Link
                  sx={{ float: "right" }}
                  variant="caption"
                  href="https://www.dukeendowment.org/privacy-policy"
                >
                  Privacy Policy
                </Link>
                <Typography
                  variant="caption"
                sx={{marginX: "5px"}}>
                  | 
                </Typography>
                <Link
                  sx={{ float: "right" }}
                  variant="caption"
                  href="https://www.dukeendowment.org/terms-conditions"
                >
                  Legal
                </Link>
              </Box>

          </Box>

{/* Definition Pop Up */}
          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={DefinitionHelpers.visible}
            onClose={handleCloseDef}
            id="definition"
          >
            <DialogContent>
              <DialogContentText component="div">
                  <HelpfulDefinition definitionId={DefinitionHelpers.key}/>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDef}>Close</Button>
            </DialogActions>
          </Dialog>
{/* FAQ & Help */}
          <Dialog
            fullWidth={true}
            maxWidth={false}
            open={open}
            onClose={handleClose}
            id="FAQs-and-help"
          >

            <DialogTitle>
              FAQs and Help
              <HelpIcon sx={{ float: "right" }} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText component="div">
                  <FaqHelpContents faqType= {FaqType.budget} />
                    {/* <FaqHelp budgetType={BudgetType}/> */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
{/* Add to application success */}
          <Dialog
            fullWidth={false}
            maxWidth={false}
            open={applicationSuccessMsg}
            onClose={handleCloseApplicationSuccessMsg}
            id="successfully-added"
          >

            <DialogTitle>
                <Typography align="center" variant="h2" sx={{ fontSize: "4ch", color: "rgb(117, 106, 78)" }} >
                Budget Added to Application!
              </Typography>

            </DialogTitle>
            <DialogContent>
                <Typography  variant="body1">
                  You have successfully completed your budget! <br/>
                  Please save this as a PDF using the Print to PDF button (legal paper size, horizontal format)<br/> and attach it to your application in the Attachments tab under Uploads under the Program Budget title. <br/>
                  You can re-attach a revised budget, if you have made changes, until the application has been submitted.
                </Typography>

            </DialogContent>
            <DialogActions>
                <Button
                  endIcon={<Print />}
                  variant="text"
                  onClick={handlePrintScreen}
                >
                  Print PDF
                </Button>
                <Button onClick={() => window.close()}>Close</Button>
            </DialogActions>
          </Dialog>
{/* Add To Application Error */}
          <Dialog
            open = {openAppErr}
            onClose = {handleCloseAppErr}
          >
            <DialogTitle >
              Unable to Add Budget to Application!
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ marginBottom: "10px" }}>
                There was an error in adding this budget to your application! You will not be able to submit the budget until the following items are resolved:
              </Typography>
              <Box sx={{paddingLeft: "20px"}}>
                {(RevenueBudgetItemsTotal() === 0) && 
                  <Alert severity="error" sx={{ marginBottom: "10px" }}>Please enter a request amount for The Duke Endowment under 'Revenue From All Sources' </Alert>
                }
                {
                  ExpenseBudgetItemsTotal() === 0 &&
                    <Alert severity="error" sx={{ marginBottom: "10px" }}>Please enter at least one expense! </Alert>
                }
              </Box>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAppErr}>Close</Button>
            </DialogActions>
          </Dialog>
{/* Save successful notification */}
          <Snackbar
            open={savedSnackBarOpen}
            autoHideDuration={3000}
            onClose={handleCloseSavedSnackBar}
          >
            <Alert
              severity="success"
              sx={{width: '100%'}}
            >
              Saved Successfully!
            </Alert>
          </Snackbar>
        </>
        )
        : <PrintBudgetScreen handlePrintScreen={handlePrintScreen}/> 
      }
    </>
  );
};
