import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
// import applicationConnectorReducer from '../features/application-connector/applicationConnectorSlice';
// import applicationAdminReducer from '../features/Common/stateSlices/applicationAdminSlice';
// import requirementsConnectorReducer from '../features/Common/stateSlices/requirementsConnectorSlice';
import budgetStateSliceReducer from '../features/Common/stateSlices/budgetStateSlice';
import bbgDataStateSlice from '../features/Common/stateSlices/bbgDataSlice';
import appStateSlice from '../features/Common/stateSlices/appStateSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    // applicationConnector: applicationConnectorReducer,
    // applicationAdmin: applicationAdminReducer,
    // requirementsConnector: requirementsConnectorReducer,
    budgetState: budgetStateSliceReducer,
    bbgDataState: bbgDataStateSlice,
    appState: appStateSlice
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
