import React from "react"
// import { MigrationDropZone } from "./MigrationDropZoneArchive"
import MigrationDropZone from './MigrationDropZone'
import { Box, Card, Button, Stack, Typography, CardContent, CardHeader, TextField, CardActions} from "@mui/material"
import AutoComplete from "@mui/material/Autocomplete";
import {programAreasList, iProgramAreas, budgetTypes} from '../../app/budgetTypes'
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { tabTypes } from '../../app/models';
import { setBudgetType, selectBudgetType, selectTotalYears, setTotalYears, setBudgetId, setBudgetKey } from "../Common/stateSlices/budgetStateSlice";
import { setMigrationCurrentTab, selectMigrationCurrentTab, addMigrationTab } from '../Common/stateSlices/appStateSlice';
import { uuidv4 } from "../Common/helpers";

export const MigrationChooseMigration = (props) => {
    const dispatch = useAppDispatch();
    const totalYears= useAppSelector(selectTotalYears);
    const budgetType = useAppSelector(selectBudgetType)
    const budgetTypeItem = programAreasList.find( programArea => budgetType === programArea.id)
    const [newBudget, setNewBudget] = React.useState(false)
    const handleSetNewBudget = () =>{
        setNewBudget(!newBudget)
    }

    const currentTab = useAppSelector(selectMigrationCurrentTab)
    console.log(totalYears)

    function handleSubmit (){
        dispatch(setBudgetId(uuidv4()));
        dispatch(setBudgetKey(uuidv4()));
        dispatch(setMigrationCurrentTab( currentTab +1 ))
        dispatch(addMigrationTab({tab: tabTypes.bbgId, navigate: true}))
    }

    return (
        <Box >
            <Typography 
                variant="h4" 
                fontSize="3ch" 
                justifySelf={"center"} 
                paddingLeft={"5ch"} 
                marginBottom={"5ch"}
            > 
                Start Your Budget
            </Typography>

            <Stack marginLeft={"20ch"} marginY={"4ch"} direction={"row"} gap={"5ch"} justifyContent={""}>
                {!newBudget && 
                    <>   
                        <MigrationDropZone  accept={"image/*"} open={false}/>
                        <Typography  alignSelf={"center"}> or </Typography>
                    </>
                }
                <Card>
                    <CardHeader title="Create Budget From Scratch"/>
                    <CardContent>
                        {newBudget &&
                            <Stack gap={"3ch"}>

                                    <TextField
                                        id="outlined-select-years"
                                        type="number"
                                        fullWidth = {true}
                                        label="Project Duration (years)"
                                        required
                                        defaultValue=""
                                        value={totalYears || ""}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        dispatch(setTotalYears(Number(event.target.value)));
                                        }}
                                    />
                                <AutoComplete
                                    id= "program-area"
                                    options={programAreasList.sort( (a,b) => -b.label[0].localeCompare(a.label[0]) )}
                                    renderInput={(params) => <TextField {...params} label={`Enter or Select`} />}
                                    value={budgetTypeItem}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, newInputVal: iProgramAreas) => {
                                        console.log(newInputVal?.id || "test", event)
                                    dispatch(setBudgetType(newInputVal?.id || budgetTypes.GENERAL))
                                    }}
                                />
                            </Stack>
                        }
                    </CardContent>
                    <CardActions>
                        <Stack direction="row" width="100%" justifyContent="space-between">
                            <Button
                                    variant={!newBudget ? "contained" : "outlined"}
                                    sx={{marginY:"3ch"}}
                                    onClick={handleSetNewBudget}
                                >
                                    {!newBudget ? "Click Here" : "Go back"}
                            </Button>
                            {newBudget &&                    
                                <Button 
                                    variant="contained"
                                    sx={{marginY:"3ch"}}
                                    disabled = {totalYears === undefined}
                                    onClick = {handleSubmit}
                                >
                                    Next
                                </Button>
                            }
                        </Stack>
                    </CardActions>
                </Card>
            </Stack>

        </Box>

    )
}

