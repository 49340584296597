import { Box, Typography } from '@mui/material';
import generatePDF, { Resolution, Margin, Options } from 'react-to-pdf'

export function PrintHeader(props:{typePrintBudget: string, lineTwo: string, grantNumber: string | null} ): JSX.Element {
   const {typePrintBudget, lineTwo, grantNumber = null }= props; 
   const now = new Date().toLocaleDateString()


   return (
      <Box marginLeft="10ch" marginTop="10ch">
         <Typography variant='h1' fontSize={"2rem"} fontWeight={500}> The Duke Endowment Budget Portal - {typePrintBudget}</Typography>
         <Typography variant="h2" fontSize={"1.5rem"} fontWeight={400}> {lineTwo} </Typography>
         { grantNumber ? <Typography variant='h3' fontSize={"1rem"} fontWeight={400}>Grant Number: {grantNumber}</Typography> : null}
         <Typography variant='caption'> Printed:{now} </Typography>
      </Box>
   )
}

export const printToPDF = (componentRef, fileName, callback ) => {
    const options: Options = {
        filename: fileName,
        method: 'save',
        resolution: Resolution.NORMAL,
        page: {
           margin: Margin.SMALL,
           format: 'legal',
           orientation: 'landscape',
        },
        canvas: {
           mimeType: 'image/png',
           qualityRatio: 1
        },
        overrides: {
           pdf: {
              compress: true
           },
           canvas: {
              useCORS: true
           }
        },
     };
     console.log("generating PDF!")
     generatePDF(componentRef, options) 
     console.log("PDF completed?")
     callback()
     return "Completed"
}