import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useAppSelector } from "../../../app/hooks";
import { budgetTypes } from "../../../app/budgetTypes";
import {
    selectBudgetType
} from '../../Common/stateSlices/budgetStateSlice';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const budgetSamples = {
    SI1: "SI Sample Budget",
    HE1: "HE Sample budgets",
    HC1: "HC Sample Budget",
    RC1: "RC Sample Budget",
    CFWB1: "CFWB Sample Budget"
}


function ProgressIntroductionMsg(props: { id }) {
    const TypicalBody = (
            <>
                <Typography variant="body1">The Endowment requires budget reports throughout the life of a grant that communicate essential information about current and future status. The budget portion of the report focuses on how projected revenue and expenses compare to the actual revenue and expenses. We understand that circumstances may have changed since the application or last report. Please add additional rows or use the Budget Narrative window within step 3 to explain any changes or variances. </Typography>
                <br />
                <Typography variant="body1">To speed up the reporting process, please be sure to:</Typography>
            </>
        )
    const typicalText = [
        "Report the total revenue and expenses of the program rather than only revenue from the Endowment’s grant and related expenses.",
        "Include any narrative to clarify or explain the budget on step 3 of this process.",
        "If you have supplementary budget information for this report, please attach as “other information” within the main application (the Endowment’s budget form must still be completed)."
    ];
    const TypicalBullets = (
        <>
            {
                typicalText.map( bulletPt =>{
                    return (<li key={bulletPt}><Typography variant="body1">{bulletPt}</Typography></li>)
                } ) 
            }
        </>
    )

    const {id} = props;
    const typDirIndirMsg = <li><Typography variant="body1">Please report direct and indirect expenses separately in their respective sections of the budget screen.</Typography></li>
    const typStaffMsg = <li><Typography variant="body1">Itemize expenses for each personnel position included in the grant award. </Typography></li>
    switch (id) {
        case budgetTypes.SI1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        <li><Typography variant="body1">Itemize expenses for each personnel position included in the grant award; all other grant-funded positions should be listed under the consultant fees/contracts expense category. </Typography></li>
                        <li><Typography variant="body1">Administrative Costs includes direct costs for office supplies, printing, postage, computers, phones, and program supplies.</Typography></li>
                        <li><Typography variant="body1">Travel should include expenses for trips directly needed to deliver the project, including for training or home visiting. </Typography></li>
                        <li><Typography variant="body1">Indirect Expense Rate. Please include the indirect rate provided by Endowment staff. The rate may include activities or resources that benefit more than one project and/or are necessary for the organization’s operational success (e.g., rent, utilities, Human Resources, Information Technology, office equipment, etc.) </Typography></li>
                        {typDirIndirMsg}
                    </ul>
                </>
            )
        case budgetTypes.CFWB1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        {typStaffMsg}
                        {typDirIndirMsg}
                    </ul>
                </>
            )
        case budgetTypes.RC1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        {typStaffMsg}
                        <li><Typography variant="body1"><b>Summer Literacy Grantees</b> – if you are a Summer Literacy grantee please use expense categories from the dropdown list with the prefix “SL” </Typography></li>
                    </ul>
                </>
            )
        case budgetTypes.HC1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        {typStaffMsg}
                    </ul>
                </>
            )
        case budgetTypes.HE1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        {typStaffMsg}
                    </ul>
                </>
            )
        default:
            return (<>
                {TypicalBody}
                <ul>
                    {TypicalBullets}
                    {typStaffMsg}
                </ul>
            </>)
    }
}
function FinalIntroductionMsg(props: { id }) {
    const TypicalBody = (
            <>
            <Typography variant="body1">The Endowment requires a final budget report as part of the grant award. The final report details how projected revenue and expenses compared to actual revenue and expenses for the entire grant period. This comprehensive look ensures we have the full picture of how the grant was expended. Please add additional rows or use the Budget Narrative window within step 3 to explain any changes or variances. </Typography>
                <br />
                <Typography variant="body1">To speed up the reporting process, please be sure to:</Typography>
            </>
        )
    const typicalText = [
        "Report the total revenue and expenses of the program rather than only revenue from the Endowment’s grant and related expenses.",
        "Include any narrative to clarify or explain the budget on step 3 of this process. ",
        "If you have supplementary budget information for this report, please attach as “other information” within the main application (the Endowment’s budget form must still be completed).",
        "Itemize expenses for each personnel position included in the grant award. "
    ]
    const TypicalBullets = (
        <>
            {
                typicalText.map( bulletPt =>{
                    return (<li key={bulletPt}><Typography variant="body1">{bulletPt}</Typography></li>)
                } ) 
            }
        </>
    )

    const {id} = props;
    switch (id) {
        case budgetTypes.SI1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        <li><Typography variant="body1">Itemize expenses for each personnel position included in the grant award; all other grant-funded positions should be listed under the consultant fees/contracts expense category.</Typography></li>
                        <li><Typography variant="body1">Administrative Costs includes direct costs for office supplies, printing, postage, computers, phones, and program supplies.</Typography></li>
                        <li><Typography variant="body1">Travel should include expenses for trips directly needed to deliver the project, including for training or home visiting</Typography></li>
                        <li><Typography variant="body1">Indirect Expense Rate. Please include the indirect rate provided by Endowment staff. The rate may include activities or resources that benefit more than one project and/or are necessary for the organization’s operational success (e.g., rent, utilities, Human Resources, Information Technology, office equipment, etc.) </Typography></li>
                    </ul>
                </>
            )
        case budgetTypes.CFWB1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                    </ul>
                </>
            )
        case budgetTypes.RC1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        <li><Typography variant="body1">Itemize expenses for each personnel position included in the grant award. </Typography></li>
                        <li><Typography variant="body1"><strong>Summer Literacy Grantees</strong> – if you are a Summer Literacy grantee and need to additional rows, please use expense categories from the dropdown list with the prefix “SL”. </Typography></li>                         
                    </ul>
                </>
            )
        case budgetTypes.HC1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        <li><Typography variant="body1">The budget should reflect the costs of the entire program, not just the portion you are requesting from the Endowment. </Typography></li>
                        <li><Typography variant="body1">Other sources of funding including (any projected program revenues) should be itemized along with related expenses covered by other funding sources. Please see the sample budget form here.</Typography></li>
                        <li><Typography variant="body1">The budget form should include all relevant expenses.  In the event where additional details breaking out expenses categories are needed, you have the option to upload a separate budget narrative.  </Typography></li>
           
                    </ul>
                </>
            )
        case budgetTypes.HE1:
            return (
                <>
                    <Typography variant="body1">
                        Thank you for your grant application. The budget is an important part of your application, providing a snapshot of how the Endowment’s support will be applied to the proposed initiative, project or program.<br/>  
                        <br/>
                        As you complete your budget, please keep in mind: 
                    </Typography>
                    <ul>
                        <li><Typography variant="body1">The budget should reflect the cost of the entire initiative, project or program, not just the portion you are requesting support for from the Endowment. </Typography></li>                 
                        <li><Typography variant="body1">You should reflect any school contribution in the Revenue section of the budget, along with support from other funders or external entities. </Typography></li>                 
                        <li><Typography variant="body1">You may attach a previously developed budget as “Other Information” within the main application.  This budget form, however, must also be completed and will become an important part of the annual reporting process. </Typography></li>                 
                    </ul>
                </>
            )
        default:
            return (<>
                {TypicalBody}
                <ul>
                    {TypicalBullets}
                </ul>
            </>)
    }
}

const downloadBudget = (props: { id: budgetTypes }) => {
    const {id} = props

    fetch(`../../${budgetSamples[id]}.pdf`).then(response => {
        response.blob().then(blob => {
            console.log(response)
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `${ budgetSamples[id] }.pdf`;
            alink.click();
        })
    })
}

export const ProgressReportIntroduction = (props: { reqType: "progress" | "final" }) => {
    const {reqType} = props;
    const budgetType = useAppSelector(selectBudgetType)
    const [fileDownloadedOpen, setFileDownloaded] = React.useState(false)
    console.log(budgetType)

    const handleCloseFileDownloaded = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFileDownloaded(false)
    }
    return (
        <Box
            component="form"
            sx={{
                "& .MuiTextField-root": { width: "50ch" },
                "& .MuiFormControl-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <Typography sx={{fontSize: "5ch", fontWeight: "semi-bold", marginY: "1ch"}} variant="h1"> Let's Get Started </Typography>
            { 
                reqType === "progress"
                    ? <ProgressIntroductionMsg id={budgetType} />
                    : <FinalIntroductionMsg id={budgetType}/>
            }
            
            <Button variant="contained" onClick={() => {downloadBudget({id: budgetType}); setFileDownloaded(true)}}>Download a sample budget.</Button>
            {/* Downloaded file notification */}
            <Snackbar
                open={fileDownloadedOpen}
                autoHideDuration={3000}
                onClose={handleCloseFileDownloaded}
            >
                <Alert
                    severity="info"
                    sx={{ width: '100%' }}
                >
                    File downloaded! Please check your downloads folder.
                </Alert>
            </Snackbar>
        </Box>
    );
}




