import {useState, useEffect} from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { BudgetNarrativeDescriptions } from "./BudgetNarrativeDescriptions";
import {
    selectReportingPeriodNarrative,
    setReportingPeriodNarrative,
    selectCurrentProgressReport
  } from "../../../Common/stateSlices/budgetStateSlice";

// const progressReportId = 468562;

const useDebounce = (value: any, delay: number)  => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const timer = setTimeout(() => {
        setDebouncedValue(value);
        }, delay);

        return () => {
        clearTimeout(timer);
        };
    }, [value, delay]);
    return debouncedValue;
}

export const BudgetNarrative = (props: {progressReportId:number}) => {
    const {progressReportId} = props;
    const [open, setOpen] = useState (false);
    const currentProgressReport = useAppSelector(selectCurrentProgressReport);
    console.log(currentProgressReport);
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }
    const maxCharCount = 2000;

    const debouncedDelay = 2000;
    const alertDisappearDelay = 2000;
    const dispatch = useAppDispatch();
    const ReportingPeriodNarrative = useAppSelector(selectReportingPeriodNarrative);
    const BudgetLength = ReportingPeriodNarrative(progressReportId).length
    const deboucedBudgetNarrative = useDebounce(ReportingPeriodNarrative, debouncedDelay);
    useEffect(() => {
        if (deboucedBudgetNarrative) {
            setOpen(true);
        }
    },[deboucedBudgetNarrative,dispatch])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
        console.log(event.target.value)
        event.target.value.length < (maxCharCount + 1) && 
        dispatch(setReportingPeriodNarrative({narrative: event.target.value}))
        // setOpen (true);
    }
    const isCurrentYearTab = true;
    return(
        <>
            <Box component="form" padding="15px" >
                <Typography variant="subtitle1" sx={{fontSize: "2.3ch", lineHeight: "1.2", marginBottom: ".2ch", textAlign: 'center'}}>Narrative</Typography>
                <Typography sx={{ fontStyle: "italic", color: 'red', fontSize: '1.5ch' }} variant="body1">Not required</Typography>
                <BudgetNarrativeDescriptions />
                <TextField 
                    onChange={handleChange}
                    // value={BudgetNarrative}
                    value={ReportingPeriodNarrative(progressReportId)}
                    rows={12} 
                    multiline 
                    InputProps={{
                        readOnly: isCurrentYearTab? false : true,
                    }}
                    sx={{paddingTop: "1ch", width: "100%"}}
                />
                <Box display="flex" justifyContent={"flex-end"} width="100%">
                    <Typography 
                        paddingRight={"10px"} 
                        paddingTop={"8px"} 
                        variant="caption"
                        color={BudgetLength === 1000 ? "red" : "grey"}
                    >
                        {BudgetLength.toLocaleString()}/{maxCharCount.toLocaleString()}
                    </Typography>
                </Box>
            </Box>
            <Snackbar
                open={open}
                autoHideDuration={alertDisappearDelay}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert
                    severity="success"
                    sx={{width: '100%'}}
                >
                    Narrative Saved!
                </Alert>
          </Snackbar>
        </>
    )
}