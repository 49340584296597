import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import AutoComplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import Alert from "@mui/material/Alert";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  DollarType,
  BudgetID,
  BudgetType
} from "../../app/models";
import {
  setDefinitionHelpers
  } from '../Common/stateSlices/appStateSlice';
import {
  addBudgetItemsLine,
  removeBudgetItemsForYear, 
  selectBudgetItems,
  selectCategoryDropDownOptions,
  selectBudgetItemsCategorySubTotalYear,
  selectBudgetItemsCategoryTotalYear,
  selectBudgetItemsCategoryTotal,
  selectBudgetItemsCategorySubTotal,
  selectBudgetItemsSubTotal,
  selectBudgetItemYear,
  setRowName,
  setBudgetItemsForYear,
  selectExpenseTypesAvailable,
  } from '../Common/stateSlices/budgetStateSlice';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { formatDollarValues, handleDollarInput } from "../Common/helpers";
import { TableCellBorderLR, TableCellBorderL, TableCellBorderR } from '../Common/ThemedMuiComponents';

function createBudgetRow(name: string = "", reqdLine: boolean = false) {
  return {
    name,
    reqdLine,
  };
}

const revenue = [createBudgetRow("The Duke Endowment", true)];


export function BudgetCategoryRow(props: {
  row: BudgetType;
  years: number;
}) {
  const { row, years = 3} = props;
  const [open, setOpen] = React.useState(true);
  const [rows, setRows] = React.useState(
    row.name === "Revenue Source" ? revenue : []
  );

  const budgetItems = useAppSelector(selectBudgetItems);
  const categoryDropDownOptions = useAppSelector(selectCategoryDropDownOptions)
  const budgetItemYear = useAppSelector(selectBudgetItemYear);
  const budgetItemsSubTotal = useAppSelector(selectBudgetItemsSubTotal);
  const budgetItemsCategorySubTotal = useAppSelector(selectBudgetItemsCategorySubTotal);
  const budgetItemsCategorySubTotalYear = useAppSelector(selectBudgetItemsCategorySubTotalYear);
  const budgetItemsCategoryTotal = useAppSelector(selectBudgetItemsCategoryTotal);
  const budgetItemsCategoryTotalYear = useAppSelector(selectBudgetItemsCategoryTotalYear);
  const indirectExpenses = useAppSelector(selectExpenseTypesAvailable).hasIndirectExpenses;
  const dispatch = useAppDispatch();
  const addRow = () => {
    dispatch(addBudgetItemsLine({category: row.id}))
  };

  const catName = (indirectExpenses && row.groupName === "Expenses") ? row.altGroupName : row.groupName
  const deleteRow = (index: number) => {
    dispatch(removeBudgetItemsForYear({category: row.id, index: index}));
  };
  const dropDownOptions = categoryDropDownOptions(row.id)
  const handleDefClickOpen = (helperKey: string) => {
    (helperKey === BudgetID.programExpense && !indirectExpenses )
      ? dispatch(setDefinitionHelpers({ visible: true, key: "expenses" }))
      : dispatch(setDefinitionHelpers({ visible: true, key: helperKey }))
  }

  return (
    <React.Fragment>
      <TableRow 
        sx={{
           "& > *": { borderBottom: "unset" },
          backgroundColor: "rgba(117, 106, 78,.1)",
          borderTop: "2px solid rgba(0,0,0,.3)",
          borderBottom: "2px solid rgba(0,0,0,.3)"
      }}>
{/* collapse icon or uncollapse icon in the beginning */}
        <TableCell sx={{ width: "5ch", padding: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
{/* Category name header */}
{/* REVENUE, DIRECT EXPENSES AND INDIRECT EXPENSES */}
        <TableCell
          component="th"
          scope="row"
          sx={{
            width: "38ch",
            fontWeight: "bold",
            textTransform: "uppercase",
            padding: "1ch"
          }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
          >
            {catName}
            <IconButton
              size="small"
              sx={{ position: 'relative', top: '-8px', padding: 0 }}
              id="test"
              onClick={() => handleDefClickOpen(row.id)}
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Typography>
        </TableCell>
        <TableCell/>
{/* Category total header */}
{/* Header for Total Amount  for YEAR 1 - 3, and TOTAL FOR REVENUE, DIRECT EXPENSES AND INDIRECT EXPENSES seperately */}
        {Array.apply(null, Array(years)).map((_, index) => {
          return (
            <React.Fragment key={index}>
              <TableCellBorderLR sx={{ fontWeight: "bold", fontSize: "1rem" }} colSpan={2} align="center">
                {formatDollarValues(budgetItemsCategoryTotalYear(index + 1, row.id))}
              </TableCellBorderLR>
            </React.Fragment>
          )
        })}
        <TableCellBorderL
          sx={{ backgroundColor: "rgba(117, 106, 78,.03)", borderColor: "rgba(0,0,0,.2)", fontWeight: "bold", minWidth: "12ch", maxWidth: "12ch", fontSize: "1rem" }}
          align="center"
          colSpan={2}
        >
          {formatDollarValues(budgetItemsCategoryTotal(row.id))}
        </TableCellBorderL>
{/* Budget Item Row */}
      </TableRow>
      {open &&
        budgetItems[row.id].map((insideRow, rowIndex) => (
          <TableRow key={rowIndex} 
          sx={{ 
            "& > *": { borderBottom: "unset" }
          }}>
{/* delete icon button or null */}
            <TableCell sx={{ minWidth: "4ch", padding: 0 }}>
              {insideRow.reqdLine ? (
                null
              ) : (
                <Tooltip title="Delete row">
                  <IconButton onClick={() => deleteRow(rowIndex)}>
                    <RemoveCircleOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
            <TableCell component="th" scope="row" sx={{ minWidth: "35ch", paddingLeft: "20px"}}>
              {insideRow.reqdLine ? (
// revenue or expense title for each row in the category of Revenue, direct expenses or indirect expenses
                <> 
                  <div>
                    <Typography>{insideRow.lineName}
                      {insideRow.reqdLine && <Tooltip title={insideRow.toolTip}>
                        <IconButton size="small" sx={{ position: 'relative', top: '-8px', padding: 0 }}>
                          <InfoOutlinedIcon />
                        </IconButton>

                        </Tooltip>
                      }
                    </Typography>
                  </div>
                </>
              ) : (
// expenses items input box
                <FormControl fullWidth size="small">
                  {(true)  
                    ? <AutoComplete
                        id= "outlined-adornment-line-name"
                        spellCheck = {true}
                        freeSolo
                        placeholder = {`Enter ${row.name}...`}
                        options={dropDownOptions}
                        renderInput={(params) => <TextField {...params} label={`Enter or Select ${row.groupName}`} />}
                        onInputChange={(event: React.ChangeEvent<HTMLInputElement>, newInputVal) => {
                          dispatch(setRowName({ category: row.id, index: rowIndex, lineName: newInputVal }))
                        }}
                        value={budgetItems[row.id]?.[rowIndex]?.lineName || ""}
                      />
                    : <OutlinedInput
// Might use in the future
                        id="outlined-adornment-line-name"
                        spellCheck={true}
                        placeholder={`Enter ${row.name}...`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setRowName({ category: row.id, index: rowIndex, lineName: event.target.value }))
                        }}
                        value={budgetItems[row.id]?.[rowIndex]?.lineName || ""}
                      />
                }
                  
                </FormControl>
              )}
            </TableCell>
            <TableCell sx={{padding: 0, width: 0}}>
{/* left and right side of new amount input box for year 1 - 3*/}
            </TableCell>
{/* Map for number of years*/}
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <React.Fragment key={index}>
                  <>
                    <TableCellBorderL align="right" sx={{ padding: 1 }}>
                      <FormControl size="small" sx={{ width: "17ch" }}>
                        { (row.id !== BudgetID.revenue || insideRow.reqdLine) &&
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            sx={{input: {textAlign: 'right'}}}
                            placeholder={"--"}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={(budgetItemYear(row.id, rowIndex, index + 1, DollarType.TDE) || "").toLocaleString()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              dispatch(setBudgetItemsForYear({ index: rowIndex, year: index + 1, category: row.id, amount: handleDollarInput(event.target.value), typeDollars: DollarType.TDE }));
                            }}
                          />
                        }
                      </FormControl>
                    </TableCellBorderL>
                    <TableCellBorderR align="right" sx={{ padding: 1 }}>
                      { !insideRow.reqdLine &&
                        <FormControl size="small" sx={{ width: "17ch" }}>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            sx={{input: {textAlign: 'right'}}}
                            disabled={insideRow.reqdLine}
                            // placeholder={`Y${index + 1} Dollars`}
                            placeholder={"--"}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={(budgetItemYear(row.id, rowIndex, index + 1, DollarType.Other) || "").toLocaleString()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              dispatch(setBudgetItemsForYear({ index: rowIndex, year: index + 1, category: row.id, amount: handleDollarInput(event.target.value), typeDollars: DollarType.Other }));
                            }}
                          />
                        </FormControl>
                      }
                    </TableCellBorderR>
                  </>             
                </React.Fragment>
              );
            })}
{/* left and right side of new expenses amount input box for total*/}
            <TableCellBorderL
              align="right"
              sx={{ backgroundColor: "rgba(117, 106, 78,.03)", minWidth: "14ch", maxWidth: "14ch" }}
            >
              <Typography>
                {formatDollarValues(budgetItemsSubTotal(row.id, DollarType.TDE, rowIndex))}
              </Typography>
            </TableCellBorderL>
            <TableCellBorderR
              align="right"
              sx={{ backgroundColor: "rgba(117, 106, 78,.03)", minWidth: "14ch", maxWidth: "14ch" }}
            >
              <Typography>
                {formatDollarValues(budgetItemsSubTotal(row.id, DollarType.Other, rowIndex))}
              </Typography>
            </TableCellBorderR>
          </TableRow>
        ))}
      {open && (
      <>
        <TableRow sx={{
          borderBottom: "1px solid rgba(0,0,0,.1)"
        }}>
          <TableCell sx={{ borderBottom: "0px solid black" }} />
{/* ADD new Expense Button Or Alert for the Max Line  */}
          <TableCell sx={{ borderBottom: "0px solid black", paddingY: "5px"}} >
            {budgetItems[row.id].length > row.maxItems - 1 ? (
              <>
                {row.id === row.id ? (
                  <Alert icon={<HelpOutlineIcon fontSize="inherit" />} severity="warning" variant="outlined" sx={{ marginBottom: 1 }}>
                    Bundle all other {row.groupName} in single line item.
                  </Alert>
                ) : null}
                <Alert severity="info" >Max number of rows allowed.</Alert>
              </>
            ) : (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={addRow}
              >
                Add {row.name}
              </Button>
            )}
          </TableCell>
{/* Blank spacing after Add button */}
          <TableCell/>
          <TableCell colSpan={2*years} align="right" sx={{ padding: 1, borderWidth: 0 }}/>
          <TableCell
            align="right"
            colSpan={2}
            sx={{ backgroundColor: "rgba(117, 106, 78,.03)", minWidth: "14ch", maxWidth: "14ch" }}
          />
        </TableRow>
{/* SubTotals Row */}
        <TableRow sx={{ 
          backgroundColor: "rgba(117, 106, 78,.03)", 
            height: "20px",
            // borderBottom: "2px solid rgba(0,0,0,.3)"
          }}>
          <TableCell />
          <TableCell sx={{ padding: "8px" }} >
            <Typography>Sub-Totals for {row.name}</Typography>
          </TableCell>
          <TableCell/>
          {Array.apply(null, Array(years)).map((_, index) => {
            return (
              <React.Fragment key={index}>
                <TableCellBorderL sx={{ paddingY: "8px" }}  align="right">
                  <Typography>
                    {formatDollarValues(budgetItemsCategorySubTotalYear(index + 1, row.id, DollarType.TDE))}
                  </Typography>
                </TableCellBorderL>
                <TableCellBorderR sx={{ paddingY: "8px" }}  align="right">
                  <Typography>
                    {formatDollarValues(budgetItemsCategorySubTotalYear(index + 1, row.id, DollarType.Other))}
                  </Typography>
                </TableCellBorderR>
              </React.Fragment>
            )
          })}
          <TableCellBorderL
              sx={{ backgroundColor: "rgba(117, 106, 78,.03)",  minWidth: "12ch", maxWidth: "12ch", paddingY: "8px" }}
            align="right"
          >
            <Typography>
                {formatDollarValues(budgetItemsCategorySubTotal(row.id, DollarType.TDE))}
            </Typography>
          </TableCellBorderL>          
          <TableCell
              sx={{ backgroundColor: "rgba(117, 106, 78,.03)",  minWidth: "12ch", maxWidth: "12ch", paddingY: "8px" }}
            align="right"
          >
            <Typography>
                {formatDollarValues(budgetItemsCategorySubTotal(row.id, DollarType.Other))}
            </Typography>
          </TableCell>          
        </TableRow>
{/* Totals Row */}
        <TableRow sx={{ 
          backgroundColor: "rgba(117, 106, 78,.03)", 
            height: "20px",
            borderBottom: "2px solid rgba(0,0,0,.3)"
          }}>
          <TableCell />
          <TableCell sx={{ padding: "8px" }} >
            <Typography>Totals for {row.name}</Typography>
          </TableCell>
          <TableCell/>
          {Array.apply(null, Array(years)).map((_, index) => {
            return (
              <React.Fragment key={index}>
                <TableCellBorderL sx={{ padding: "8px" }} colSpan={2}  align="center">
                  <Typography>
                    {formatDollarValues(budgetItemsCategoryTotalYear(index + 1, row.id))}
                  </Typography>
                </TableCellBorderL>
              </React.Fragment>
            )
          })}
          <TableCellBorderL
            sx={{ backgroundColor: "rgba(117, 106, 78,.03)",  minWidth: "12ch", maxWidth: "12ch", padding: "8px" }}
            colSpan = {2}
            align="center"
          >
            <Typography>
                {formatDollarValues(budgetItemsCategoryTotal(row.id))}
            </Typography>
          </TableCellBorderL>          
        </TableRow>
      </>
      )}
      { row.id == BudgetID.revenue &&
        <TableRow >
          <TableCell sx={{padding: "5px"}}/>
        </TableRow> 
      }
    </React.Fragment>
  );
}
