import { BudgetID, BudgetTypeExpenses } from "../../../app/models"
import { budgetTypes } from "../../../app/budgetTypes";

//  Program Area types
const SI1 :budgetTypes = budgetTypes.SI1;
const CFWB1 :budgetTypes = budgetTypes.CFWB1;
const RC1 :budgetTypes = budgetTypes.RC1;
const HE1 :budgetTypes = budgetTypes.HE1;
const HC1 :budgetTypes = budgetTypes.HC1;
const General = "GENERAL";

// TODO - move items below into Program Area types

const yearOptionsArr = {
    [SI1]: [1,2,3,4,5,6,7,8,9,10],
    [CFWB1]: [1,2,3,4,5],
    [RC1]: [1,2,3,4,5],
    [HE1]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [HC1]: [1,2,3,4,5],
    [General]: [1,2,3,4,5]
}

const inKindSupport = {
    [SI1]: true,
    [CFWB1]: true,
    [RC1]: true,
    [HC1]: true,
    [HE1]: true,
    [General]: true
}

const indirectSupport = {
    [SI1]: true,
    [CFWB1]: true,
    [RC1]: false,
    [HC1]: false,
    [HE1]: false,
    [General]: false
}

const expensesSupport = {
    [SI1]: true,
    [CFWB1]: true,
    [RC1]: true,
    [HC1]: true,
    [HE1]: true,
    [General]: true
}

const endowmentSupported = {
    [SI1]: false,
    [CFWB1]: false,
    [RC1]: false,
    [HC1]: false,
    [HE1]: false,
    [General]: false
}

const expenseTypes: {Name: string, type: BudgetID, ProgramAreas: string[]}[] = [
    {Name: "Administrative", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Benefit(s)", type: BudgetID.programExpense, ProgramAreas: [CFWB1]},
    {Name: "Benefits (fringe & required)", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Building/Equipment Maintenance", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Consultants", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Hardware", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Insurance", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Interns", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "IT Support", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Meals", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Rent", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Research", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Software Systems", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Supplies", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Technology", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Training", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Transportation", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Continuing Education", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Contract Services", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Tuition", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Utilities", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "SL- Wages and Payroll Taxes, Workers Comp", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "SL- Food- real $s and in-kind", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "SL- Classroom supplies", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "SL- Field Trips and Travel", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "SL- Incentives (Student and Family)", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "SL- Facilities Expenses", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "SL- Admin and Volunteer labor", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "SL- Technology", type: BudgetID.programExpense, ProgramAreas: [RC1]},
    {Name: "Salaries", type: BudgetID.programExpense, ProgramAreas: [RC1, HE1]},
    {Name: "Salary(ies)", type: BudgetID.programExpense, ProgramAreas: [CFWB1]},
    {Name: "Training/On-going coaching", type: BudgetID.programExpense, ProgramAreas: [CFWB1]},
    {Name: "Supervision", type: BudgetID.programExpense, ProgramAreas: [CFWB1]},
    {Name: "Travel", type: BudgetID.programExpense, ProgramAreas: [CFWB1, RC1]},
    {Name: "Materials/Supplies", type: BudgetID.programExpense, ProgramAreas: [CFWB1]},
    {Name: "Equipment", type: BudgetID.programExpense, ProgramAreas: [CFWB1, RC1]},
    {Name: "Evaluation", type: BudgetID.programExpense, ProgramAreas: [CFWB1, HE1]},
    {Name: "Financial oversight (Audit, payroll, etc)", type: BudgetID.indirectExpense, ProgramAreas: [CFWB1]},
    {Name: "Information Technology", type: BudgetID.indirectExpense, ProgramAreas: [CFWB1]},
    {Name: "Fund Development", type: BudgetID.indirectExpense, ProgramAreas: [CFWB1]},
    {Name: "Human Resources", type: BudgetID.indirectExpense, ProgramAreas: [CFWB1]},
    {Name: "Agency rent/utilities/maintenance", type: BudgetID.indirectExpense, ProgramAreas: [CFWB1]},
    {Name: "Other-please itemize", type: BudgetID.indirectExpense, ProgramAreas: [CFWB1]},
    { Name: "Other-please itemize", type: BudgetID.programExpense, ProgramAreas: [CFWB1, RC1]},
    {Name: "Contribution from applicant", type: BudgetID.revenue, ProgramAreas:[CFWB1, HE1, RC1, HC1, General]},
    {Name: "Gifts in-kind", type: BudgetID.revenue, ProgramAreas:[CFWB1, HE1, HC1, RC1, General]},
    {Name: "SL - Contributions from Host Church/Churches–both real $’s and in-kind", type: BudgetID.revenue, ProgramAreas:[RC1]},
    {Name: "SL - Community organization and individual donations––both real $’s and in-kind", type: BudgetID.revenue, ProgramAreas:[RC1]},
    {Name: "SL - UM/Denominational Conference and district grants–real $’s only", type: BudgetID.revenue, ProgramAreas:[RC1]},
    {Name: "SL - School district partner contributions–in-kind", type: BudgetID.revenue, ProgramAreas:[RC1]},
    {Name: "Financial Aid/Scholarships", type: BudgetID.programExpense, ProgramAreas:[HE1]},
    {Name: "Fellowships", type: BudgetID.programExpense, ProgramAreas:[HE1]},
    {Name: "Professorships", type: BudgetID.programExpense, ProgramAreas:[HE1]},
    {Name: "Training/Coaching", type: BudgetID.programExpense, ProgramAreas:[HE1]},
    {Name: "Materials/Supplies/Equipment", type: BudgetID.programExpense, ProgramAreas:[HE1]},
    {Name: "Capital Project", type: BudgetID.programExpense, ProgramAreas:[HE1]}
]

export const ProgramValues = (ProgramId) =>{
    return (
        {
            yearOptionsArr: yearOptionsArr[ProgramId] || [1,2,3,4,5],
            expensesSupport: expensesSupport[ProgramId] || true,
            inKindSupport: inKindSupport[ProgramId] || false,
            indirectSupport: indirectSupport[ProgramId] || false,
            endowmentSupport: endowmentSupported[ProgramId] || false,
            expenseTypes: expenseTypes.filter( type => type.ProgramAreas.includes(ProgramId)) || []
        }
    )
}