import React, { useState } from "react";
import {  Box, Button, Input, Typography, Stack } from "@mui/material";
import { BudgetTable } from "../budget-table/BudgetTable";
import { BudgetNarrative } from "../budget-narrative/BudgetNarrative";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectTotalYears } from "../Common/stateSlices/budgetStateSlice";
import { tabTypes } from "../../app/models";
import { selectMigrationTabs, addMigrationTab, setMigrationCurrentTab } from "../Common/stateSlices/appStateSlice";

export const MigrateInitialBudget = (props) => {
    const dispatch = useAppDispatch()
    const totalYears = useAppSelector(selectTotalYears)
    const migrationTabs = useAppSelector(selectMigrationTabs)
    const progressReportsExists = migrationTabs.includes(tabTypes.progressReports)
    const [seeNarrative, setSeeNarrative] = useState(true)

    const handleAddSubmit = () => {
        dispatch(addMigrationTab({tab: tabTypes.submitBudgets , navigate: true}))
    }

    function handleVisiblity(){
        setSeeNarrative(!seeNarrative)
    }

    function handleGoToBudget(){
        if(!progressReportsExists){
            dispatch(addMigrationTab({tab: tabTypes.progressReports, navigate: true}))
        }else{
            dispatch(setMigrationCurrentTab(migrationTabs.indexOf(tabTypes.progressReports)))
        }

    }
    return (
        <>
            <Stack gap="2ch">
                <Typography variant="h4" fontSize="3ch"> Review Application Budget</Typography> 
                <Button variant="contained" onClick={handleVisiblity} fullWidth={false} sx={{maxWidth: '20ch'}}>
                    {seeNarrative
                        ? "See Narrative"
                        : "Back to Budget"
                    }
                </Button>
                <Box>
                    <Typography marginBottom="2ch" fontStyle="italic">Please fill out the application budget or perform any review and/or corrections needed to make the budget correct.</Typography> 
                    {seeNarrative
                        ?<BudgetTable years={totalYears}/>
                        :<BudgetNarrative/>
                    }
                    <Stack marginTop={5} direction="row" gap="2ch" width={"100%"} justifyContent={"flex-end"}>
                        <Button variant="contained" onClick={handleAddSubmit}>Skip to Submit Page</Button>
                        <Button variant="contained" onClick={handleGoToBudget}>Go to Progress Reports</Button>
                    </Stack>
                    
                </Box>
            </Stack>
        </>
    )


}