import Typography from "@mui/material/Typography";
import { budgetTypes} from "../../app/budgetTypes";



export const BudgetNarrativeDescriptions = (props) => {
    const programArea: string = budgetTypes.RC1
    switch (programArea){
        case budgetTypes.CFWB1:
            return (<Typography sx={{fontStyle:"italic"}} variant="body1">Please enter any relevant narrative data that is needed to provide context to your budget.</Typography>);
        case budgetTypes.RC1 :
            return (<Typography sx={{ fontStyle: "italic" }} variant="body1">Please enter any relevant narrative data that is needed to provide context to your budget.</Typography>)
        default:
            return (
                <Typography sx={{ fontStyle: "italic", paddingLeft:"15px"}} variant="body1">
                    Please enter any relevant narrative information that is needed to provide context to your budget. Responses must be under 2,000 characters. <br/>
                    If further explanation is needed please upload a file in your grant request application in the Attachments tab under "Other Information" 
                </Typography>)
    }
}