import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./app/theme";
import { Layout } from "./features/layout/Layout";
import { Counter } from "./features/counter/Counter";
import { ApplicationAdmin } from "./features/application-admin/ApplicationAdmin";
import { MigrateBudget} from "./features/budget-migration/MigrateBudget";
import { ConnectorLayout } from "./features/layout-connector/ConnectorLayout";
import { RequirementsConnector } from "./features/requirements-connector/RequirementsConnector";
import { ApplicationConnector } from "./features/application-connector/ApplicationConnector";
import "./App.css";

import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalExternalConfig } from "./authExternalConfig";
import { msalInternalConfig } from "./authInternalConfig";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { ApplicationAdminEdit } from "./features/application-admin/ApplicationAdminEdit";

export var msalExternalInstance = new PublicClientApplication(msalExternalConfig);
export var msalInternalInstance = new PublicClientApplication(
  msalInternalConfig
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path="/"
          element={
            <MsalProvider instance={msalInternalInstance}>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                <Layout />
              </MsalAuthenticationTemplate>
            </MsalProvider>
          }
        >
        <Route index element={<div>Home</div>} />
          <Route
            path="migratebudget" 
            element={<MigrateBudget />} 
          />
          <Route path="budget">
            <Route path="application/:id" element={<ApplicationAdmin />} />
            <Route
              path="application/:id/edit"
              element={<ApplicationAdminEdit />}
            />
          </Route>
          <Route path="count" element={<Counter />} />
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="handle/redirect" element={<div>Redirecting...</div>} />
          <Route path="*" element={<div>Miss</div>} />
        </Route>
        <Route
          path="connector"
          // element={
          //   <MsalProvider instance={msalExternalInstance}>
          //     <MsalAuthenticationTemplate
          //       interactionType={InteractionType.Redirect}
          //     >
          //       <ConnectorLayout />
          //     </MsalAuthenticationTemplate>
          //   </MsalProvider>
          // }
        >
          <Route path="requirements/:id/:reqType/:reqId" element={<RequirementsConnector />} />
          <Route path="application/:id" element={<ApplicationConnector />} />
          <Route path="handle/redirect" element={<div>Redirecting...</div>} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
