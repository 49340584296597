import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useAppSelector } from "../../../app/hooks";
import { budgetTypes } from "../../../app/budgetTypes";
import {
    selectExpenseTypesAvailable
} from '../../Common/stateSlices/budgetStateSlice';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const budgetSamples = {
    SI1: "SI Sample Budget",
    HE1: "HE Sample budgets",
    HC1: "HC Sample Budget",
    RC1: "RC Sample Budget",
    CFWB1: "CFWB Sample Budget"
}


function IntroductionMsg(props: { id }) {
    const TypicalBody = (
            <>
                <Typography variant="body1">The budget is an integral part of the application process and plays an essential role in telling the Endowment about your program. We are typically looking for several things, including responsible use of funds, other sources of revenue (including other grants, private donations and your own funding toward the program) and how expenses align with expected revenue.</Typography>
                <Typography variant="body1">We understand that circumstances will likely change from year-to-year and, if your program is funded, budget reports will be requested annually as an update for the Endowment.  </Typography>
                <br />
                <Typography variant="body1">To speed up the application process, please be sure to:</Typography>
            </>
        )
    const TypicalBullets = (
        <>
            <li><Typography variant="body1">Include the entire cost of the program, not just the portion you are requesting from the Endowment. </Typography></li>
            <li><Typography variant="body1">Communicate your expected revenues and expenses clearly.</Typography></li>
            <li><Typography variant="body1">Include any narrative that would clarify or explain the budget on step 3 of this process. </Typography></li>
            <li><Typography variant="body1">If you have previously developed a budget for this request, please attach as “other information” within the main application – but please still complete the Endowment’s budget form. </Typography></li>

        </>
    )

    const {id} = props;
    switch (id) {
        case budgetTypes.SI1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        <li><Typography variant="body1">For Personnel/Staff and Fringe, list each position by title, if available. List only the employees of the applicant organization; all other grant-funded positions should be listed under the consultant fees/contracts expense category. </Typography></li>
                        <li><Typography variant="body1">Administrative Costs includes direct costs for office supplies, printing, postage, computers, phones, and program supplies.</Typography></li>
                        <li><Typography variant="body1">Travel should include expenses for trips directly needed to deliver the project, including for training or home visiting. </Typography></li>
                        <li><Typography variant="body1">Indirect Expense Rate.Please include the indirect rate provided by Endowment staff.The rate may include activities or resources that benefit more than one project and/or are necessary for the organization’s operational success (e.g., rent, utilities, Human Resources, Information Technology, office equipment, etc.) </Typography></li>
                    </ul>
                </>
            )
        case budgetTypes.CFWB1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        <li><Typography variant="body1">Indicate if a particular revenue source is committed or pending.</Typography>
                            <ul>
                                <li><Typography variant="body1">For example, “Foundation XYZ Grant – Pending” </Typography></li>
                            </ul>
                        </li>
                        <li><Typography variant="body1">Itemize expenses for each personnel position included in the application</Typography></li>
                        <li><Typography variant="body1">Direct and indirect expenses should be separated into the indicated sections. We will use this information to calculate an indirect cost rate that will be reviewed by program staff. <br/>There is no set indirect rate funded by the Endowment. </Typography>
                            <ul>
                                <li><Typography variant="body1">If your organization submits a set rate, then use one row to capture all indirect expenses in the indirect expenses section.</Typography></li>
                            </ul>
                        </li>
                    </ul>
                </>
            )
        case budgetTypes.RC1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        {TypicalBullets}
                        <li><Typography variant="body1">Please use <a target="_blank" rel="noopener noreferrer" href="https://independentsector.org/value-of-volunteer-time-2022/">this link</a> to calculate volunteer time</Typography></li>
                        <li><Typography variant="body1">Direct and indirect expenses should be separated into the indicated sections. We will use this information to calculate an indirect cost rate that will be reviewed by program staff. <br/> There is no set rate the Endowment funds. </Typography>
                            <ul>
                                <li><Typography variant="body1">If your organization submits a set rate, then use one row to capture all indirect expenses in the indirect expenses section. </Typography></li>
                            </ul>
                        </li>                        
                        
                        <li><Typography variant="body1"><b>Summer Literacy Grantees</b> – if you are a Summer Literacy grantee please use expense categories from the dropdown list with the prefix “SL” </Typography></li>
                    </ul>
                </>
            )
        case budgetTypes.HC1:
            return (
                <>
                    {TypicalBody}
                    <ul>
                        <li><Typography variant="body1">The budget should reflect the costs of the entire program, not just the portion you are requesting from the Endowment. </Typography></li>
                        <li><Typography variant="body1">Other sources of funding including (any projected program revenues) should be itemized along with related expenses covered by other funding sources. Please see the sample budget form here.</Typography></li>
                        <li><Typography variant="body1">The budget form should include all relevant expenses.  In the event where additional details breaking out expenses categories are needed, you have the option to upload a separate budget narrative.  </Typography></li>
           
                    </ul>
                </>
            )
        case budgetTypes.HE1:
            return (
                <>
                    <Typography variant="body1">
                        Thank you for your grant application. The budget is an important part of your application, providing a snapshot of how the Endowment’s support will be applied to the proposed initiative, project or program.<br/>  
                        <br/>
                        As you complete your budget, please keep in mind: 
                    </Typography>
                    <ul>
                        <li><Typography variant="body1">The budget should reflect the cost of the entire initiative, project or program, not just the portion you are requesting support for from the Endowment. </Typography></li>                 
                        <li><Typography variant="body1">You should reflect any school contribution in the Revenue section of the budget, along with support from other funders or external entities. </Typography></li>                 
                        <li><Typography variant="body1">You may attach a previously developed budget as “Other Information” within the main application.  This budget form, however, must also be completed and will become an important part of the annual reporting process. </Typography></li>                 
                    </ul>
                </>
            )
        default:
            return (<>
                {TypicalBody}
                <ul>
                    {TypicalBullets}
                </ul>
            </>)
    }
}

const downloadBudget = (props: { id: budgetTypes }) => {
    const {id} = props

    fetch(`../../${budgetSamples[id]}.pdf`).then(response => {
        response.blob().then(blob => {
            console.log(response)
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `${ budgetSamples[id] }.pdf`;
            alink.click();
        })
    })
}

export const BudgetIntroduction = (props: {id: budgetTypes}) => {
    const {id} = props;
    const budgetCategories = useAppSelector(selectExpenseTypesAvailable);
    const { hasIndirectExpenses } = budgetCategories;
    const [fileDownloadedOpen, setFileDownloaded] = React.useState(false)

    const handleCloseFileDownloaded = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFileDownloaded(false)
    }
    return (
        <Box
            component="form"
            sx={{
                "& .MuiTextField-root": { width: "50ch" },
                "& .MuiFormControl-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <Typography sx={{fontSize: "5ch", fontWeight: "semi-bold", marginY: "1ch"}} variant="h1"> Let's Get Started </Typography>
            <IntroductionMsg id={id}/>
            <Button variant="contained" onClick={() => {downloadBudget({id}); setFileDownloaded(true)}}>Download a sample budget.</Button>
            {/* Downloaded file notification */}
            <Snackbar
                open={fileDownloadedOpen}
                autoHideDuration={3000}
                onClose={handleCloseFileDownloaded}
            >
                <Alert
                    severity="info"
                    sx={{ width: '100%' }}
                >
                    File downloaded! Please check your downloads folder.
                </Alert>
            </Snackbar>
        </Box>
    );
}




