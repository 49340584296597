import React, { useState, useEffect, ReactElement } from "react";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { MigrateConnectBbg } from "./MigrateConnectBbg";
import { MigrateProgressReport } from "./MigrateProgressReport";
import { current } from "@reduxjs/toolkit";
import { MigrateInitialBudget } from "./MigrateInitialBudget";
import { MigrateConnectProgressReports } from "./MigrateConnectProgressReports";
import { MigrationChooseMigration } from "./MigrationChooseMigration";
import { MigrateSubmitBudgets } from "./MigrateSubmitBudgets";
import { tabTypes } from "../../app/models";
import { addMigrationTab, setMigrationCurrentTab, selectMigrationCurrentTab, selectMigrationTabs } from "../Common/stateSlices/appStateSlice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { ThemeProvider } from "@mui/material";
import { theme } from '../../app/theme'

type iTabInterface = {
  label: string;
  id: string;
  view: ReactElement
}

const START = "start"; const BBGID = "bbgId"; const BUDGET = "budget"; const PROGRESSREPORTS = "progressReports"

const getTab = (id):iTabInterface => {
  const localTabsReference: iTabInterface[] = [
    {
      label: "Start",
      id: tabTypes.start,
      view: <MigrationChooseMigration/>,
    },
    {
      label: "Blackbaud",
      id: tabTypes.bbgId,
      view: <MigrateConnectBbg/>,
    },
    {
      label: "Application Budget",
      id: tabTypes.budget,
      view: <MigrateInitialBudget/>,
    },
    {
      label: "Connect Progress Reports",
      id: tabTypes.progressReports,
      view: <MigrateConnectProgressReports/>,
    },
    {
      label: "Submit Budgets",
      id: tabTypes.submitBudgets,
      view: <MigrateSubmitBudgets/>
    }
  ]

  const selectTab = (id) => localTabsReference.filter( tab => tab.id === id)[0]
  console.log(id, selectTab(id))
  if( id.includes("progressReportPage") ){
    const reportId:string = id.split("progressReportPage")[1];
    const reportIdNum: number = parseInt(reportId);
    return (
      {
        label: `Progress Report ${reportId}`,
        id: id,
        view: <MigrateProgressReport progressReportId={reportIdNum}/>
      }
    )
  }
  return  selectTab(id)
}


export const MigrateBudget = () => {
  const dispatch = useAppDispatch();

  // const tabsReference: iTabInterface[] = [
  //   {
  //     label: "Start",
  //     id: START,
  //     view: <MigrationChooseMigration handleAddTab={handleAddTab}/>,
  //   },
  //   {
  //     label: "Blackbaud",
  //     id: BBGID,
  //     view: <MigrateConnectBbg/>,
  //   },
  //   {
  //     label: "Initial Budget",
  //     id: BUDGET,
  //     view: <MigrateInitialBudget/>,
  //   },
  //   {
  //     label: "Connect Progress Reports",
  //     id: PROGRESSREPORTS,
  //     view: <MigrateConnectProgressReports/>,
  //   },
  //   // {
  //   //   label: "Progress Report 1",
  //   //   id: "progressReport1",
  //   //   view: "</>",
  //   // }
  // ]
  const migrationTabs = useAppSelector(selectMigrationTabs)
  const availableTabs = migrationTabs.map( id => getTab(id))
  console.log(availableTabs)
  const currentTab = useAppSelector(selectMigrationCurrentTab)


  // const [availableTabs, setAvailableTabs] = React.useState<iTabInterface[]> ([startTab])
  // console.log(availableTabs)
  // const [currentTab, setCurrentTab] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    // setCurrentTab(newValue);
    dispatch(setMigrationCurrentTab(newValue))
  };

  function handleAddTab (newTab) {
    dispatch(addMigrationTab(newTab))
  }

  const handleNextTab = () =>{
    const nextTab = currentTab + 1;
    console.log(nextTab)
    // setCurrentTab(nextTab)
  }

  return (
    <ThemeProvider theme={theme}>
      <Card  sx={{paddingX: "30px", minHeight: "500px"}}>
        <CardHeader title="Migrate an Old Budget"/>
        <CardContent >
          <Tabs indicatorColor="secondary" value={currentTab} onChange={handleChangeTab} role="navigation">
            {availableTabs.map( tab => <Tab key={tab.id} label={tab.label}/>)}
          </Tabs>
          <Box marginTop="3ch">
            {availableTabs[currentTab].view}
          </Box>

        </CardContent>
      </Card>

    </ThemeProvider>
  );
};
