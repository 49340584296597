import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAppSelector } from "../../../../app/hooks";
import {ReviewBudgetCategoryRow} from "./ReviewBudgetCategoryRow";
import {
  AvailableBudgetTypes,
  BudgetID
} from "../../../../app/models";
import {
  selectExpenseBudgetItemsSumYear,
  selectExpenseTypesAvailable,
  selectExpenseBudgetItemsTotal,
  selectBudgetItemsCategoryTotal,
  selectIndirectDirectExpenseRatio,
  selectIndirectDirectExpenseRatioYear,
  selectBudgetItemsCategoryTotalYear, 
  selectTotalYears
} from '../../../Common/stateSlices/budgetStateSlice';
import { formatDollarValues } from "../../../Common/helpers";
import { TableCellBorderL, TableCellBorderLR, TableCellBorderR } from "../../../Common/ThemedMuiComponents";


export const ReviewBudgetTable = () => {

  const budgetCategories = AvailableBudgetTypes(useAppSelector(selectExpenseTypesAvailable))
  const { revenue } = BudgetID
  const years = useAppSelector(selectTotalYears)
  const ExpenseBudgetItemsTotal = useAppSelector(selectExpenseBudgetItemsTotal);
  const ExpenseBudgetItemsSumYear = useAppSelector(selectExpenseBudgetItemsSumYear);
  const budgetItemsCategoryTotalYear = useAppSelector(selectBudgetItemsCategoryTotalYear);
  const budgetItemsCategoryTotal = useAppSelector(selectBudgetItemsCategoryTotal);
  const IndirectDirectExpenseRatio = useAppSelector(selectIndirectDirectExpenseRatio);
  const IndirectDirectExpenseRatioYear = useAppSelector(selectIndirectDirectExpenseRatioYear);
  const indirectExpenseAvailable = useAppSelector(selectExpenseTypesAvailable).hasIndirectExpenses;

  return (
    <TableContainer id="printableArea" sx={{ mx: "auto", width: "calc(100vw*.9)" }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ borderBottom: "0px solid", width: "35ch" }}
            ></TableCell>
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <TableCellBorderLR
                  key={index}
                  sx={{
                    borderBottom: "0px solid",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    fontSize: "1.1rem"
                  }}
                  align="center"
                  colSpan={2}
                >
                  Year {index + 1}
                </TableCellBorderLR>
              );
            })}
            <TableCellBorderL
              sx={{
                borderBottom: "0px solid",
                fontWeight: "bold",
                textTransform: "uppercase",
                minWidth: "20ch",
                maxWidth: "20ch",
              }}
              align="center"
              colSpan={2}
            >
              Total
            </TableCellBorderL>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "35ch" }} />
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <React.Fragment key={index}>
                  <TableCellBorderL 
                  //sx={{borderBottom: "solid 0"}} 
                  align="center">TDE</TableCellBorderL>
                  <TableCellBorderR align="center">Other</TableCellBorderR>
                </React.Fragment>
              );
            })}
            <TableCellBorderL
              align="center"
              sx={{ minWidth: "8ch", maxWidth: "14ch" }}
            >
              TDE
            </TableCellBorderL>
            <TableCellBorderR
              align="center"
              sx={{ minWidth: "8ch", maxWidth: "14ch" }}
            >
              Other
            </TableCellBorderR>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgetCategories
            .map((row) => (
              <ReviewBudgetCategoryRow
                key={row.id}
                row={row}
                years={years}
              />
            ))}
            <TableRow >
              <TableCell sx={{ padding: "5px" }} />
            </TableRow> 
{/* Sub-Total Revenue Row */}
          {/* <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)", borderTop: "2px solid rgba(0,0,0,.3)" }}>
            <TableCell
              style={{ borderBottom: "0px solid", fontSize: ".95rem"}}
              sx={{ width: "35ch" }}
            >
              Revenue Sub-Totals
            </TableCell>
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <>
                  <TableCellBorderL
                    key={index}
                    style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                    align="right"
                  >
                    {formatDollarValues(budgetItemsCategorySubTotalYear(index + 1, revenue, DollarType.TDE))}
                  </TableCellBorderL>
                  <TableCellBorderR
                    key={index}
                    style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                    align="right"
                  >
                    {formatDollarValues(budgetItemsCategorySubTotalYear(index + 1, revenue, DollarType.Other))}
                  </TableCellBorderR>
                </>
              );
            })}
            <TableCellBorderL
              style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic" }}
              align="right"
              sx={{ minWidth: "8ch", maxWidth: "14ch" }}
            >
              {formatDollarValues(budgetItemsCategorySubTotal(revenue, DollarType.TDE))}
            </TableCellBorderL>
            <TableCellBorderR
              style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic" }}
              align="right"
              sx={{ minWidth: "8ch", maxWidth: "14ch" }}
            >
              {formatDollarValues(budgetItemsCategorySubTotal(revenue, DollarType.Other))}
            </TableCellBorderR>
          </TableRow> */}
{/* Total Revenue Row */}
          <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)", borderTop: "2px solid rgba(0,0,0,.3)" }}>
            <TableCell
              style={{ borderBottom: "0px solid", fontSize: ".95rem"}}
              sx={{ width: "35ch" }}
            >
              Total Revenue
            </TableCell>
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <TableCellBorderLR
                  key={index}
                  style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                  align="center"
                  colSpan={2}
                >
                  {formatDollarValues(budgetItemsCategoryTotalYear(index + 1, revenue))}
                </TableCellBorderLR>
              );
            })}
            <TableCellBorderL
                style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              sx={{ minWidth: "8ch", maxWidth: "14ch" }}
              colSpan={2}
            >
              {formatDollarValues(budgetItemsCategoryTotal(revenue))}
            </TableCellBorderL>
          </TableRow>
{/* Sub-Total Expenses Row */}
          {/* <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)" }}>
            <TableCell
              style={{ borderBottom: "0px solid", fontSize:".95rem" }}
              sx={{ width: "35ch" }}
            >
              Expenses Sub-Totals
            </TableCell>
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <>
                  <TableCellBorderL
                    key={index}
                    style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                    align="right"
                    colSpan={1}
                  >
                    {formatDollarValues(ExpenseBudgetItemsSubTotalYear(index + 1, DollarType.TDE))}
                  </TableCellBorderL>
                  <TableCellBorderR
                    key={index}
                    style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                    align="right"
                    colSpan={1}
                  >
                    {formatDollarValues(ExpenseBudgetItemsSubTotalYear(index + 1, DollarType.Other))}
                  </TableCellBorderR>
                </>
              );
            })}
            <TableCellBorderL
              style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic" }}
              align="right"
              colSpan={1}
              sx={{ minWidth: "14ch", maxWidth: "28ch" }}
            >
              {formatDollarValues(ExpenseBudgetItemsSubTotal(DollarType.TDE)) }
            </TableCellBorderL>
            <TableCellBorderR
              style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic" }}
              align="right"
              colSpan={1}
              sx={{ minWidth: "14ch", maxWidth: "28ch" }}
            >
              {formatDollarValues(ExpenseBudgetItemsSubTotal(DollarType.Other)) }
            </TableCellBorderR>
          </TableRow> */}
{/* Total Expenses Row */}
          <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)" }}>
            <TableCell
              style={{ borderBottom: "0px solid", fontSize:".95rem" }}
              sx={{ width: "35ch" }}
            >
              Total Expenses
            </TableCell>
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <TableCellBorderLR
                  key={index}
                  style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                  align="center"
                  colSpan={2}
                >
                  {formatDollarValues(ExpenseBudgetItemsSumYear(index + 1))}
                </TableCellBorderLR>
              );
            })}
            <TableCellBorderL
              style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              colSpan={2}
              sx={{ minWidth: "14ch", maxWidth: "28ch" }}
            >
              {formatDollarValues(ExpenseBudgetItemsTotal())}
            </TableCellBorderL>
          </TableRow>
{/* Net Dollar Excess */}
          <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)" }} style={{ border: "0px solid"}}>
            <TableCell
              sx={{
                borderBottom: "0px solid",
                width: "35ch"
              }}
            >
              Net Dollar Excess (Deficit)
            </TableCell>
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <TableCellBorderLR
                  key={index}
                  sx={{
                    borderBottom: "0px solid",
                    fontWeight: "bold",
                    color: ((budgetItemsCategoryTotalYear(index + 1, revenue) - ExpenseBudgetItemsSumYear(index + 1)) < 0) ? "red" : "black"
                  }}
                  align="center"
                  colSpan={2}
                >
                  {formatDollarValues(budgetItemsCategoryTotalYear(index + 1, revenue) - ExpenseBudgetItemsSumYear(index + 1))}
                </TableCellBorderLR>
              );
            })}
            <TableCellBorderL
              sx={{
                backgroundColor: "rgba(117, 106, 78,.03)",
                borderBottom: "0px solid",
                minWidth: "24ch",
                maxWidth: "28ch",
                fontWeight: "bold",
                color: (budgetItemsCategoryTotal(revenue) - ExpenseBudgetItemsTotal() < 0) ? "red" : "black"
              }}
              align={"center"}
              colSpan={2}
            >
              {formatDollarValues(budgetItemsCategoryTotal(revenue) - ExpenseBudgetItemsTotal())}
            </TableCellBorderL>
          </TableRow>
{/* Indirect/Direct Ration Row */}
          {indirectExpenseAvailable &&
            <TableRow id="indirectDirectRatio" sx={{ backgroundColor: "rgba(117, 106, 78,.05)", borderBottom: "2px solid rgba(0,0,0,.3)" }}>
            <TableCell
              style={{ borderBottom: "0px solid", fontSize: ".95rem" }}
              sx={{ width: "35ch" }}
            >
              Indirect/Direct Ratio
            </TableCell>
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <TableCellBorderLR
                  key={index}
                  style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                  align="center"
                  colSpan={2}
                >
                  {IndirectDirectExpenseRatioYear(index + 1) ? IndirectDirectExpenseRatioYear(index + 1).toLocaleString() + "%" : "--"}
                </TableCellBorderLR>
              );
            })}
            <TableCellBorderL
              style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              colSpan={2}
              sx={{ minWidth: "14ch", maxWidth: "28ch" }}
            >
              {IndirectDirectExpenseRatio() ? IndirectDirectExpenseRatio().toLocaleString() + "%" : "--"}
            </TableCellBorderL>
          </TableRow>}

        </TableBody>
      </Table>
    </TableContainer>
  );
};
