import React, {useContext, useState, useRef} from 'react'
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from '@mui/material/IconButton';
import { Box, Button, OutlinedInput } from "@mui/material";
import Typography from "@mui/material/Typography";
import {CurBtnStatusContext} from "../../RequirementsConnector";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import {
    setActualDate,
    selectActualEndDate, 
  } from "../../../Common/stateSlices/budgetStateSlice";
import {
    selectBeginingOrEndingDateString,
    selectApplicablePaymentsAmount,
  } from "../../../Common/stateSlices/bbgDataSlice";

import { 
    progressReportDatePairs,
    yearlyUpdateId,
    dateTerms
} from "../../../../app/models"
/**
 * 
 * @returns 
 */


export const RequirementsDateInput = (props: { progressReportId: number, yearlyUpdateCategoryId: yearlyUpdateId, bbgDates: { dates: progressReportDatePairs}}) => {
    const {yearlyUpdateCategoryId, progressReportId} = props;
    //use context provider from RequirementsConnector
    const setNotDisableBtn = useContext(CurBtnStatusContext);
    const beginningOrEndingDateString = useAppSelector(selectBeginingOrEndingDateString);
    const paymentAmount = useAppSelector(selectApplicablePaymentsAmount);


    const getActualEndDate = useAppSelector(selectActualEndDate)
    const actualEndDate = getActualEndDate(progressReportId);
    const actualEndDateExists = actualEndDate !== "";
    !actualEndDateExists && setNotDisableBtn(true)

    const beginningDateString = beginningOrEndingDateString(progressReportId, yearlyUpdateCategoryId, dateTerms.beginning)
    const endingDateString = beginningOrEndingDateString(progressReportId, yearlyUpdateCategoryId, dateTerms.ending)

    const [edit, setEdit] = useState(false);
    const valueRef = useRef({value: ''});

    const dispatch = useAppDispatch();

    const EndingDateActualEditor = () => {

        function handleAddDate(){
            console.log(valueRef.current.value)
            const actualEndDateString = valueRef.current.value
            dispatch(setActualDate({dateString: actualEndDateString}));
            setNotDisableBtn(false)
            const { actual } = yearlyUpdateId
            const beginningDate = new Date(beginningOrEndingDateString(progressReportId, actual, dateTerms.beginning))
             const tdePaymentValueActual = paymentAmount(beginningDate, new Date(valueRef.current.value))
    
            // dispatch(
            //     setProgressReportItemValue({ progressReportId, category: BudgetID.revenue, index: 0, budgetType: actual, amount: tdePaymentValueActual, typeDollars: DollarType.TDE })
            // )
            setEdit(false)
        }

        function handleCancel(){
            setEdit(false)
        }

        if(edit){
            return  <>
                        <OutlinedInput sx={{ width: '170px', fontWeight: 'bold' }}
                            placeholder="MM/DD/YYYY"
                            defaultValue={actualEndDate}
                            inputRef={valueRef}
                            type="date"
                            disabled={yearlyUpdateCategoryId !== "actual"}
                        />
                        <IconButton onClick={handleCancel}>
                            <ClearIcon/>
                        </IconButton>
                        <IconButton onClick={handleAddDate}>
                            <CheckIcon/>
                        </IconButton>
                    </>
        }  else{
            return <>
                    {actualEndDateExists
                        ? <>     
                            <Typography sx={{ paddingLeft: '2ch', paddingY: '1ch', fontWeight: 'bold' }}> {endingDateString}</Typography>
                            <IconButton type="submit" onClick={()=>setEdit(true)}>
                                    <EditOutlinedIcon/>
                                </IconButton>
                            </>
                        : <Button variant='contained' onClick={()=>setEdit(true)} sx={{marginTop: "1ch"}}>Add Actual End Date</Button>
                    }
                    </>
        }
    }

    return (
        <><FormControl>
            <FormLabel>
                Beginning Date
            </FormLabel>
            <Typography sx={{  
                color: beginningDateString === "Missing Date" ? "red": "inherit",
                paddingLeft: '2ch', 
                paddingY: '1ch', 
                fontWeight: 'bold' 
                }}>{beginningDateString}
            </Typography>
        </FormControl>
        <FormControl>
            <FormLabel>
                Ending Date
            </FormLabel>
            <Box sx={{display:'flex'}}>
                { yearlyUpdateCategoryId !== "actual"
                    ? <>
                        <Typography sx={{ 
                            color: endingDateString === "Missing Date" ? "red": "inherit", 
                            paddingLeft: '2ch', 
                            paddingY: '1ch', 
                            fontWeight: 'bold' 
                            }}>{endingDateString}
                        </Typography>
                        </>
                    : <EndingDateActualEditor/>
                }
            </Box>
        </FormControl>
    </>
    )

}

