import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper'
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import {ReviewBudgetTable} from "../budget-table-review/ReviewBudgetTable";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectTotalYears,
  selectSavingStatus,
  selectBudgetNarrative,
  fetchBudgetIdAsyncAdmin,
  selectBudgetLoading,
  selectBudgetId,
  selectFinalReportId,
  selectProgressReports,
  selectNotFound
} from "../Common/stateSlices/budgetStateSlice";
import {
  fetchBlackbaudRequestRefAsync,
  fetchBlackbaudRequirementRefAsync,
  selectBlackbaudRequestRef,
  fetchBlackbaudPaymentRefAsync,
  selectRequestStatus,
  selectRequestId
} from "../Common/stateSlices/bbgDataSlice";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDollarValues } from "../Common/helpers";

import { ProgressReportTable } from "../requirements-connector/requirements-connector-parts/progress-report-table/ProgressReportTable";
import { FinalReportTable } from "../requirements-connector/requirements-connector-parts/final-report-table/FinalReportTable";

// testing data
const testingId = "bc5f059e-edc2-4fb9-a23f-aa7134dc7f34"
const testingKey = "e721369b-235f-411e-997a-4d595d46b74b"

// const BudgetSummaryDetails = ({ disabled = true }: { disabled?: boolean }) => {
//   const totalYears = useAppSelector(selectTotalYears);
//   const projectTitle = useAppSelector(selectProjectTitle);
//   const dispatch = useAppDispatch();

//   return (
//     <Box
//       component="form"
//       sx={{
//         "& .MuiTextField-root": { m: 1, width: "50ch" },
//       }}
//       noValidate
//       autoComplete="off"
//     >
//       <Typography variant="h6" component="div">
//         Budget Summary
//       </Typography>
//       <Stack spacing={8}>
//         <TextField
//           required
//           disabled={disabled}
//           id="outlined-required"
//           label="Project Title"
//           value={projectTitle || ""}
//           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//             dispatch(setProjectTitle(event.target.value));
//           }}
//         />
//         <TextField
//           id="outlined-select-currency"
//           required
//           disabled={disabled}
//           select
//           label="Select Number of Year"
//           defaultValue=""
//           value={totalYears || ""}
//           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//             dispatch(setTotalYears(Number(event.target.value)));
//           }}
//         >
//           {currencies.map((option) => (
//             <MenuItem key={option.value} value={option.value}>
//               {option.label}
//             </MenuItem>
//           ))}
//         </TextField>
//       </Stack>
//     </Box>
//   );
// };

// const AvailableFields = () => {
//   return(
//     <FormControl component="fieldset" variant="standard">
//       <FormLabel component="legend">Update Fields Available to the Grantee</FormLabel>
//       <FormGroup>
//         <FormControlLabel
//           control={
//             <Switch checked={true} name="In-Kind" />
//           }
//           label="In-Kind"
//         />
//         <FormControlLabel
//           control={
//             <Switch checked={true} name="Indirect" />
//           }
//           label="Indirect Expenses"
//         />
//         <FormControlLabel
//           control={
//             <Switch checked={true} name="Endowments" />
//           }
//           label="Endowments"
//         />
//       </FormGroup>
//     </FormControl>
//   )
// }

// const BudgetReview = () => {
//   const projectTitle = useAppSelector(selectProjectTitle);
//   const totalYears = useAppSelector(selectTotalYears);


//   const summaryItems = [
//     {title: "Total Years", content: "4"},
//     { title: "Total Revenue", content: "$500,000"},
//     { title: "Total Expenses", content: "$525,000"},
//     { title: "Total Request", content: "$150,000"}
//   ]

//   return (
//     <Box
//       component="form"
//       sx={{
//         "& .MuiTextField-root": { m: 1, width: "50ch" },
//       }}
//       noValidate
//       autoComplete="off"
//     >
//       <Typography variant="h6" component="div" sx={{ mb: 2 }}>
//         Budget Summarys
//       </Typography>
//       <Typography
//         gutterBottom
//         variant="subtitle1"
//         component="div"
//         sx={{ ml: 1 }}
//       >
//         Project Title: <b>{projectTitle}</b>
//       </Typography>
//       <Box sx={{display: 'flex', justifyContent: "space-between"}}>
//         {summaryItems.map( item => {
//           return(<Card variant="outlined" sx={{ ml: "10px", width: "200px", height: "75px", flexGrow: 1 }}>
//             <Typography
//               gutterBottom
//               variant="subtitle1"
//               component="div"
//               sx={{ textAlign: "center" }}
//             >
//               {item.title}:
//             </Typography>
//             <Typography
//               sx={{textAlign: "center" }}
//             >
//               <b>{item.content}</b>
//             </Typography>
//           </Card>)
//         })

//         }
//       </Box>

//       <Typography
//         gutterBottom
//         variant="subtitle1"
//         component="div"
//         sx={{ ml: 1 }}
//       >
//         Net Amount: <b>-$25,000</b>
//       </Typography>
//     </Box>
//   );
// };

// const EditFields = () => {
//   return (
//     <Box>

//     </Box>
//   )
// }

const BudgetNarrative = () => {
  const BudgetNarrative = useAppSelector(selectBudgetNarrative)
  return (
    <Box component={Paper} sx={{margin: "2ch", minHeight: "200px", paddingX: "20px", paddingY: "10px"}}>
      { BudgetNarrative.split('\n').map( txt => <Typography key={txt}>{txt}</Typography>) } 
    </Box>
  )
}

const BudgetTableAndNarrative = () =>  {
  
  const [tab, setTab] = React.useState(0);

  const years = useAppSelector(selectTotalYears);

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) =>{
    console.log(newTab)
    setTab(newTab)
  }

  const tabViews = [
    {
      label: "Budget",
      view: <ReviewBudgetTable years={years}/>,
    },
    {
      label: "Narrative",
      view: <BudgetNarrative/>
    }
  ];

  return (
    <>
    <Tabs
      value = {tab}
      onChange={handleChangeTab}
      textColor="secondary"
      indicatorColor="secondary"
      sx={{
        marginTop: '2ch',
        marginLeft: '4ch'
      }}
    >
      {tabViews.map( tab => <Tab key={tab.label} label={tab.label}/>)}
    </Tabs>
    {tabViews[tab].view}
    </>
  )

}

const BudgetInfo = () => {
  let projectInfo = { organization: {name: ""}, contact: {name: ""}, projectTitle: "", disposition:"", requestAmount: 0, grantAmount: 0, paidAmount: 0, staff: {name: ''} }
  const bbgRequestStatus = useAppSelector(selectRequestStatus)
  const BlackbaudRequestRef = useAppSelector(selectBlackbaudRequestRef);
  if (bbgRequestStatus === 'loaded'){
    projectInfo = BlackbaudRequestRef
  }

  const { organization, projectTitle, contact, requestAmount, grantAmount, staff} = projectInfo;
  return(
    <TableContainer sx={{marginTop: "2ch"}} component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{width: "10%", minWidth: "20ch"}}>Organization:</TableCell>
            <TableCell>{organization.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Project Title:</TableCell>
            <TableCell>{projectTitle}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Contact:</TableCell>
            <TableCell>{contact.name}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell sx={{ width: "10%" }}>Contact:</TableCell>
            <TableCell>{contact.name}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Request:</TableCell>
            <TableCell>{formatDollarValues(requestAmount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Grant Amount:</TableCell>
            <TableCell>{formatDollarValues(grantAmount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Assigned Staff Member:</TableCell>
            <TableCell>{staff.name}</TableCell>
          </TableRow>
        </TableBody>

      </Table>
    </TableContainer>
  )
}

export const ApplicationAdmin = () => {
  const [tabNum, setTabNum] = React.useState(0);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectRequestStatus);
  const savingStatus = useAppSelector(selectSavingStatus);
  const blackbaudRequestRef = useAppSelector(selectBlackbaudRequestRef);
  const notFound =  useAppSelector(selectNotFound);


  // constants for requirements
  const budgetLoading = useAppSelector(selectBudgetLoading);
  const budgetId = useAppSelector(selectBudgetId);
  const progressReports = useAppSelector(selectProgressReports);
  const finalReportId: number = useAppSelector(selectFinalReportId)
  const finalReportExists: boolean = finalReportId !== 0 && finalReportId !== null;

  const requestId = useAppSelector(selectRequestId);

  useEffect(() => {
    dispatch(fetchBlackbaudRequestRefAsync(id));
    dispatch(fetchBlackbaudRequestRefAsync(id));
    dispatch(fetchBudgetIdAsyncAdmin(id)) //dispatch(fetchBudgetIdAsyncAdmin(id))
    // dispatch(fetchBudgetIdAsyncConnector("8af2aaba-b596-4193-96de-9a7c78a7d394"));
  }, [id]);

  useEffect(() => {
    if(budgetLoading === "loaded"){
      // get Request from BBG
      dispatch(fetchBlackbaudRequestRefAsync(budgetId))
      // get usable Progress Reports
      progressReports.forEach(reportId => dispatch(fetchBlackbaudRequirementRefAsync(reportId)))
    }
    
  }, [budgetLoading])

  useEffect(() => {
    if(requestStatus === "loaded"){

      dispatch(fetchBlackbaudPaymentRefAsync(requestId))
    }
  }, [requestStatus])


  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    setTabNum(newValue);
  };

  const initialTabs = [
    {
      label: "Grant",
      view: <BudgetInfo/>,
    },
    {
      label: "Application Budget",
      view: <BudgetTableAndNarrative />,
    },
    // {
    //   label: "Application Budget",
    //   view: <ReviewBudgetTable years={totalYears}/>,
    // },
    // {
    //   label: "Narrative",
    //   view: <BudgetNarrative/>
    // }
  ];

  const progressReportArr = progressReports.map((item, index) => ({label: `Progress Report ${index + 1}`, view: <ProgressReportTable progressReportId={item} disabled={true}/>}))
  const finalReportArr = finalReportExists ? [{label: "Final Report", view: <FinalReportTable disabled={true}/>}] : []

  const tabs = [
              ...initialTabs, 
              ...progressReportArr,
              ...finalReportArr
            ];

  return (
    <>
      <Card variant="outlined" sx={{paddingX: "30px"}}>
        {savingStatus === "saving" ? (
          <LinearProgress />
        ) : (
          <Box sx={{ height: "4px"}} />
        )}
        <CardHeader
          title={
            <>
              Budget Information for Grant Number {blackbaudRequestRef?.referenceNumber}
              {blackbaudRequestRef?.customFields
                ?.tde_budget_application_status ? (
                null
              ) : null}
            </>
          }
          subheader={"Please review the grant budget details."}
        />
        <Tabs indicatorColor="secondary" value={tabNum} onChange={handleChangeTab}>
          {tabs.map( tab => <Tab key={tab.label} label={tab.label}/>)}
        </Tabs>
        {tabs[tabNum].view}
        <CardContent>
          <Box sx={{ width: "100%" }}>
            {requestStatus !== "loaded" ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress sx={{ margin: "auto" }} />
              </Box>
            ) : notFound ? (
              <>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ ml: 1 }}
                >
                  Not Found
                </Typography>
              </>
            ) : (
              <>
                <br/>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
