import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Faqs} from "./Faqs"
import { AdminContacts } from './AdminContacts';
import { HelpfulDefinition} from '../../Common/Definitions/HelpfulDefinitions';
import { BudgetID } from '../../../app/models';
import { selectExpenseTypesAvailable} from '../../Common/stateSlices/budgetStateSlice';
import { useAppSelector } from "../../../app/hooks";
import { FaqType } from "../../../app/models";
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function FaqHelpContents(props: {faqType: FaqType}) {
    const {faqType} = props; 
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const indirectExpenseAvailable = useAppSelector(selectExpenseTypesAvailable).hasIndirectExpenses;
    console.log(indirectExpenseAvailable);
    return (
        <Box sx={{ width: '100%', height: "80%" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="FAQs" {...a11yProps(0)} />
                    <Tab label="Helpful Definitions" {...a11yProps(1)} />
                    <Tab label="Contact Us" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Faqs faqType = {faqType}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <HelpfulDefinition definitionId="giftInKind" faqType = {faqType}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AdminContacts faqType = {faqType}/>
            </TabPanel>
        </Box>
    );
}