import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import {
  DollarType,
  BudgetID
} from "../../../../app/models";
import {
  selectBudgetItems,
  selectBudgetItemsCategorySubTotalYear,
  selectBudgetItemsCategoryTotalYear,
  selectBudgetItemsCategoryTotal,
  selectBudgetItemsCategorySubTotal,
  selectBudgetItemsSubTotal,
  selectBudgetItemYear,
} from '../../../Common/stateSlices/budgetStateSlice';
import { useAppSelector } from "../../../../app/hooks";
import { formatDollarValues } from "../../../Common/helpers"
import { TableCellBorderL, TableCellBorderR, TableCellBorderLR } from "../../../Common/ThemedMuiComponents";

function createBudgetCategory(
  id: string = "",
  name: string = "",
  groupName: string = "",
  maxItems: number = 10
) {
  return {
    id,
    name,
    groupName,
    maxItems
  };
}

export function ReviewBudgetCategoryRow(props: {
  row: ReturnType<typeof createBudgetCategory>;
  years: number;

}) {
  const { row, years = 2 } = props;

  const budgetItems = useAppSelector(selectBudgetItems);
  const budgetItemYear = useAppSelector(selectBudgetItemYear);
  const budgetItemsSubTotal = useAppSelector(selectBudgetItemsSubTotal);
  const budgetItemsCategorySubTotal = useAppSelector(selectBudgetItemsCategorySubTotal);
  const budgetItemsCategorySubTotalYear = useAppSelector(selectBudgetItemsCategorySubTotalYear);
  const budgetItemsCategoryTotal = useAppSelector(selectBudgetItemsCategoryTotal);
  const budgetItemsCategoryTotalYear = useAppSelector(selectBudgetItemsCategoryTotalYear);

  return (
    <React.Fragment>
{/* Category Header Row */}
      <TableRow 
        sx={{
           "& > *": { borderBottom: "unset" },
          backgroundColor: "rgba(117, 106, 78,.1)",
          borderTop: "2px solid rgba(0,0,0,.3)",
          borderBottom: "2px solid rgba(0,0,0,.3)"
      }}>
        <TableCell
          component="th"
          scope="row"
          sx={{
            width: "38ch",
            fontWeight: "bold",
            textTransform: "uppercase",
            padding: "8px"
          }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold", fontSize: "1rem" }}
          >
            {row.groupName}
          </Typography>
        </TableCell>
        {Array.apply(null, Array(years)).map((_, index) => {
          return (
            <React.Fragment key={index}>
              <TableCellBorderL colSpan={2} sx={{ fontWeight: "bold", fontSize: "1rem" }} align="center">
                <Typography>
                  {formatDollarValues(budgetItemsCategoryTotalYear(index + 1, row.id))}
                </Typography>
              </TableCellBorderL>
            </React.Fragment>
          )
        })}
        <TableCellBorderLR
          sx={{ backgroundColor: "rgba(117, 106, 78,.03)", borderColor: "rgba(0,0,0,.2)", fontWeight: "bold", minWidth: "12ch", maxWidth: "12ch", fontSize: "1rem" }}
          align="center"
          colSpan = {2}
        >
          <Typography>
            {formatDollarValues(budgetItemsCategoryTotal(row.id))}
          </Typography>
        </TableCellBorderLR>
      </TableRow>
{/* Item Rows */}
      { budgetItems[row.id].map((insideRow, rowIndex) => (
          <TableRow key={rowIndex} 
          sx={{ 
            //"& > *": { borderBottom: "unset" }
          }}>
            <TableCellBorderR component="th" scope="row" sx={{ minWidth: "35ch", paddingLeft: "20px"}}>
              <Typography>{insideRow.lineName}</Typography>
            </TableCellBorderR>
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <React.Fragment key={index}>
                  <>
                    <TableCellBorderL align="right">
                      <Typography>
                        {formatDollarValues(budgetItemYear(row.id, rowIndex, index + 1, DollarType.TDE))}
                      </Typography>
                    </TableCellBorderL>
                    <TableCellBorderR align="right">
                      <Typography>
                        {formatDollarValues(budgetItemYear(row.id, rowIndex, index + 1, DollarType.Other))}
                      </Typography>
                    </TableCellBorderR>
                  </>             
                </React.Fragment>
              );
            })}
            <TableCellBorderL
              align="right"
              sx={{ backgroundColor: "rgba(117, 106, 78,.03)", minWidth: "14ch", maxWidth: "14ch" }}
            >
              <Typography>
                {formatDollarValues(budgetItemsSubTotal(row.id, DollarType.TDE, rowIndex))}
              </Typography>
            </TableCellBorderL>
            <TableCellBorderR
              align="right"
              sx={{ backgroundColor: "rgba(117, 106, 78,.03)", minWidth: "14ch", maxWidth: "14ch" }}
            >
              <Typography>
                {formatDollarValues(budgetItemsSubTotal(row.id, DollarType.Other, rowIndex))}
              </Typography>
            </TableCellBorderR>
          </TableRow>
        ))}
{/*Category Sub-Totals Row  */}
        <TableRow sx={{ 
          backgroundColor: "rgba(117, 106, 78,.03)", 
          height: "20px"
          }}>
          <TableCell sx={{ paddingY: "8px" }} >
            Sub-Totals for {row.name}
          </TableCell>
          {Array.apply(null, Array(years)).map((_, index) => {
            return (
              <React.Fragment key={index}>
                <TableCellBorderL sx={{ paddingY: "8px" }}  align="right">
                  <Typography>
                    {formatDollarValues(budgetItemsCategorySubTotalYear(index + 1, row.id, DollarType.TDE))}
                  </Typography>
                </TableCellBorderL>
                <TableCellBorderR sx={{ paddingY: "8px" }}  align="right">
                  <Typography>
                    {formatDollarValues(budgetItemsCategorySubTotalYear(index + 1, row.id, DollarType.Other))}
                  </Typography>
                </TableCellBorderR>
              </React.Fragment>
            )
          })}
          <TableCellBorderL
              sx={{ backgroundColor: "rgba(117, 106, 78,.03)",  minWidth: "12ch", maxWidth: "12ch", paddingY: "8px" }}
            align="right"
          >
            <Typography>
              {formatDollarValues(budgetItemsCategorySubTotal(row.id, DollarType.TDE))}
            </Typography>
          </TableCellBorderL>          
          <TableCellBorderR
              sx={{ backgroundColor: "rgba(117, 106, 78,.03)",  minWidth: "12ch", maxWidth: "12ch", paddingY: "8px" }}
            align="right"
          >
            <Typography>
              {formatDollarValues(budgetItemsCategorySubTotal(row.id, DollarType.Other))}
            </Typography>
          </TableCellBorderR>          
        </TableRow>
{/* Category Totals Row */}
        <TableRow sx={{ 
          backgroundColor: "rgba(117, 106, 78,.03)", 
            height: "20px",
            borderBottom: "2px solid rgba(0,0,0,.3)"
          }}>
          <TableCell sx={{ padding: "8px" }} >
            Totals for {row.name}
          </TableCell>
          {Array.apply(null, Array(years)).map((_, index) => {
            return (
              <React.Fragment key={index}>
                <TableCellBorderLR sx={{ padding: "8px" }}  align="center" colSpan = {2}>
                  <Typography>
                    {formatDollarValues(budgetItemsCategoryTotalYear(index + 1, row.id))}
                  </Typography>
                </TableCellBorderLR>
              </React.Fragment>
            )
          })}
          <TableCellBorderLR
            sx={{ backgroundColor: "rgba(117, 106, 78,.03)",  minWidth: "12ch", maxWidth: "12ch", padding: "8px" }}
            align="center"
            colSpan={2}
          >
            <Typography>
              {formatDollarValues(budgetItemsCategoryTotal(row.id))}
            </Typography>
          </TableCellBorderLR>                  
        </TableRow>
      { row.id === BudgetID.revenue &&
        <TableRow >
          <TableCell sx={{padding: "5px"}}/>
        </TableRow> 
      }
    </React.Fragment>
  );
}
