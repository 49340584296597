import Typography from '@mui/material/Typography';
import { BudgetID, FaqType, yearlyUpdateCategories, yearlyUpdateId } from '../../../app/models';
import { useAppSelector } from "../../../app/hooks";

interface definitionInterface{
    title: string;
    definition: JSX.Element
}

const getYearlyCategory = (id: yearlyUpdateId) => yearlyUpdateCategories.filter(item => item.categoryId === id )[0]

function definitionsLibrary(id:string) {
    const definitions: {[key: string]: definitionInterface} = {
        [BudgetID.revenue]: {
            title: "Revenue",
            definition: <>
                            Revenue should include all sources of funding for this project or program. Please list your request from The Duke Endowment under the "TDE" column, and all other sources under "Other".<br/>
                            You will be required to fill out a field for The Duke Enowment (please make sure that it matches the amount requested in your application). <br/>
                            Suggested fields include: <br/>
                            <ul>
                    <li>From Applicant's Budget:  Monetary contributions from applicant’s budget to fund cash expenses (e.g. staffing, administrative and programming costs) </li>
                    <li>Gifts In-kind: See definition under 'FAQs and Help - Helpful Definitions' </li>
                            </ul>
                        </>
        },
        "revenueRequirements": {
            title: "Revenue",
            definition: <>
                            Revenue should include all sources of funding for this project or program. Please list your request from The Duke Endowment under the "TDE" column, and all other sources under "Other".<br/>
                            You will be required to fill out a field for The Duke Enowment (please make sure that it matches the amount requested in your application). <br/>
                            Suggested fields include: <br/>
                            <ul>
                    <li>From Applicant's Budget:  Monetary contributions from applicant’s budget to fund cash expenses (e.g. staffing, administrative and programming costs) </li>
                    <li>Gifts In-kind: See definition under 'FAQs and Help - Helpful Definitions' </li>
                            </ul>
                            <br/>
                            Revenue category names provided in past budgets (application budget or previous progress reports) may not be edited. If you need to change or split the category, please enter '0' for all revenues and create a new line(s) with the appropriate revenue description.
                        </>
        },
        giftInKind: {
            title: "Gifts In-Kind", 
            definition: <>The Endowment follows “Generally Accepted Accounting Principles” for recognizing gifts in-kind revenue, 
            compliant with <a target="_blank" rel="noopener noreferrer" href='https://asc.fasb.org/imageRoot/00/7534500.pdf'>ASC 820</a>. 
            We consider in-kind to be non-monetary support (e.g. goods, services and expertise). 
                Here are a few resources regarding <a target="_blank" rel="noopener noreferrer" href='https://learning.candid.org/resources/knowledge-base/in-kind-gifts/#:~:text=An%20in%2Dkind%20donation%20is,or%20for%20special%20event%20auctions'> sources of gifts in-kind</a>, 
                <a target="_blank" rel="noopener noreferrer" href='https://www.aicpa.org/resources/article/gifts-in-kind-reporting-contributions-of-nonfinancial-assets'> accounting for gifts in-kind</a> and <a target="_blank" rel="noopener noreferrer" href='https://independentsector.org/value-of-volunteer-time-2022/'>Independent Sector’s value of volunteer time</a>.</> 
        },
        expenses: {
            title: "Expenses",
            definition: <>The dropdowns are suggested expense categories, but you can still type in your own expense category. If you are applying for an established program please look for your program’s name at the beginning of the expense name; e.g. SL Technology. </>
        },
        [BudgetID.programExpense]: {
            title: "Direct Expenses",
            definition: <>Direct expenses are the expenses necessary to execute a project that is directly attributable and can be reasonably allocated to the project. This can include staff salaries, travel expenses, training, etc.</>
        },
        [BudgetID.indirectExpense]: {
            title: "Indirect Expenses",
            definition: <>Indirect expenses are expenses for activities or resources that benefit more than one project and/or are necessary for the organization’s operational success. Examples could include rent, utilities, human resources, information technology, office equipment, etc. </>
        },
        TDERevenueExpense: {
            title: "TDE Revenue and Expenses",
            definition: <>
                            <h3 style={{ fontSize: "2.5ch", marginTop: ".75ch", marginBottom: "0.5ch" }}>Revenue</h3>
                            Please only include the amount you are requesting from The Duke Endowment, and what those funds will be used to support.<br/>
                            <h3 style={{ fontSize: "2.5ch", marginTop: ".75ch", marginBottom: "0.5ch" }}>Expenses</h3>
                            If an expense is partially funded by the Endowment and another revenue stream split the expense between the TDE and Other columns in the same row.
                        </>
        },
        OtherRevenueExpense: {
            title: "Other Revenue and Expenses",
            definition: <>
                            <br/>
                            It is essential to show the full costs of the project, both revenue and expense.<br/>
                            <h3 style={{ fontSize: "2.5ch", marginTop: ".75ch", marginBottom: "0.5ch" }}>Revenue</h3>
                            Please include all other revenue streams from other funders, earned revenue, gifts in-kind and support from your organization’s budget. It is essential to show the full costs of the project/program, both revenue and expense. If an expense is partially funded by the Endowment and another revenue stream split the expense between the TDE and Other columns in the same row.<br/>
                            <h3 style={{ fontSize: "2.5ch", marginTop: ".75ch", marginBottom: "0.5ch" }}>Expenses</h3>
                            Include all expenses to support the project/program funded by the Endowment or other revenue streams.  
                        </>
        },
        [yearlyUpdateId.current]: {
            title: getYearlyCategory(yearlyUpdateId.current).categoryName,
            definition: <>The current budget is the amount budgeted for the year, either in the original application budget or the prior progress report's 'next reporting period' column. <br/> These values may not be edited</>
        },
        [yearlyUpdateId.actual]: {
            title: getYearlyCategory(yearlyUpdateId.actual).categoryName,
            definition: <>The Actual Reporting is the actual amount raised and spent to-date.</>
        },
        [yearlyUpdateId.projected]: {
            title: getYearlyCategory(yearlyUpdateId.projected).categoryName,
            definition: <>The projected reporting is the amount you project to spend by the end of the full reporting period INCLUDING the amount spent to-date</>
        },
        [yearlyUpdateId.nextReport]: {
            title: getYearlyCategory(yearlyUpdateId.nextReport).categoryName,
            definition: <>The next reporting period is the amount of revenue/expenses you expect to have in the next reporting cycle.</>
        },
        totalProjectBudget: {
            title: "Total Project Budget",
            definition: <>This is the original requested budget provided at the beginning of the project</>
        },
        totalProjectActual: {
            title: "Total Project Actual",
            definition: <>This is the amount of revenue and expenses actually incurred</>
        },
        projectActualOverUnder: {
            title: "Actual Over/Under Budget",
            definition: <>This is the difference between your total project budget and total project actual.</>
        }

    }
    return (definitions[id] || {title: "", definition: <></>} )
}

export const HelpfulDefinition = (definitionId, faqType: FaqType | null) => {
    const {title, definition} = definitionsLibrary(definitionId.definitionId)
    return (      
        <>     
            <Typography variant='h2' sx={{ fontSize: "3ch", fontWeight: "bold"}}> {title}</Typography>
            <Typography variant='body1' sx={{paddingLeft: "2.5ch", paddingTop: ".75ch"}}>{definition}</Typography>
        </> 
        )

}

// import Typography from '@mui/material/Typography';
// import { BudgetID, FaqType } from '../../../app/models';
// import { useAppSelector } from "../../../app/hooks";

// interface definitionInterface{
//     title: string;
//     definition: JSX.Element
// }

// function definitionsLibrary(id:string) {
//     const definitions: {[key: string]: definitionInterface} = {
//         [BudgetID.revenue]: {
//             title: "Revenue",
//             definition: <>
//                             Revenue should include all sources of funding for this project or program. Please list your request from The Duke Endowment under the "TDE" column, and all other sources under "Other".<br/>
//                             You will be required to fill out a field for The Duke Enowment (please make sure that it matches the amount requested in your application). <br/>
//                             Suggested fields include: <br/>
//                             <ul>
//                     <li>From Applicant's Budget:  Monetary contributions from applicant’s budget to fund cash expenses (e.g. staffing, administrative and programming costs) </li>
//                     <li>Gifts In-kind: See definition under 'FAQs and Help - Helpful Definitions' </li>
//                             </ul>
//                         </>
//         },
//         giftInKind: {
//             title: "Gifts In-Kind", 
//             definition: <>The Endowment follows “Generally Accepted Accounting Principles” for recognizing gifts in-kind revenue, 
//             compliant with <a target="_blank" rel="noopener noreferrer" href='https://asc.fasb.org/imageRoot/00/7534500.pdf'>ASC 820</a>. 
//             We consider in-kind to be non-monetary support (e.g. goods, services and expertise). 
//                 Here are a few resources regarding <a target="_blank" rel="noopener noreferrer" href='https://learning.candid.org/resources/knowledge-base/in-kind-gifts/#:~:text=An%20in%2Dkind%20donation%20is,or%20for%20special%20event%20auctions'> sources of gifts in-kind</a>, 
//                 <a target="_blank" rel="noopener noreferrer" href='https://www.aicpa.org/resources/article/gifts-in-kind-reporting-contributions-of-nonfinancial-assets'> accounting for gifts in-kind</a> and <a target="_blank" rel="noopener noreferrer" href='https://independentsector.org/value-of-volunteer-time-2022/'>Independent Sector’s value of volunteer time</a>.</> 
//         },
//         [BudgetID.programExpense]: {
//             title: "Direct Expenses",
//             definition: <>Direct expenses are the expenses necessary to execute a project that is directly attributable and can be reasonably allocated to the project. This can include staff salaries, travel expenses, training, etc.</>
//         },
//         [BudgetID.indirectExpense]: {
//             title: "Indirect Expenses",
//             definition: <>Indirect expenses are expenses for activities or resources that benefit more than one project and/or are necessary for the organization’s operational success. Examples could include rent, utilities, human resources, information technology, office equipment, etc. </>
//         },
//         TDERevenueExpense: {
//             title: "TDE Revenue and Expenses",
//             definition: <>
//                             <h3 style={{ fontSize: "2.5ch", marginTop: "1ch", marginBottom: "0.5ch" }}>Revenue</h3>
//                             Please only include the amount you are requesting from The Duke Endowment, and what those funds will be used to support.<br/>
//                             <h3 style={{ fontSize: "2.5ch", marginTop: "1ch", marginBottom: "0.5ch" }}>Expenses</h3>
//                             If an expense is partially funded by the Endowment and another revenue stream split the expense between the TDE and Other columns in the same row.
//                         </>
//         },
//         OtherRevenueExpense: {
//             title: "Other Revenue and Expenses",
//             definition: <>
//                             <br/>
//                             It is essential to show the full costs of the project, both revenue and expense.<br/>
//                             <h3 style={{ fontSize: "2.5ch", marginTop: "1ch", marginBottom: "0.5ch" }}>Revenue</h3>
//                             Please include all other revenue streams from other funders, earned revenue, gifts in-kind and support from your organization’s budget. It is essential to show the full costs of the project/program, both revenue and expense. If an expense is partially funded by the Endowment and another revenue stream split the expense between the TDE and Other columns in the same row.<br/>
//                             <h3 style={{ fontSize: "2.5ch", marginTop: "1ch", marginBottom: "0.5ch" }}>Expenses</h3>
//                             Include all expenses to support the project/program funded by the Endowment or other revenue streams.  
//                         </>
//         }

//     }
//     return (definitions[id] || {title: "", definition: <></>} )
// }

// export const HelpfulDefinition = (definitionId, faqType: FaqType | null) => {
//     const {title, definition} = definitionsLibrary(definitionId.definitionId)
//     return (      
//         <>     
//             <Typography variant='h2' sx={{fontSize: "3.5ch", fontWeight: "bold"}}> {title}</Typography>
//             <Typography variant='body1' sx={{paddingLeft: "2.5ch"}}>{definition}</Typography>
//         </> 
//         )

// }