import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const TableCellBorderLR = styled(TableCell)(({ theme }) => ({
    borderLeft: "1px solid rgba(0,0,0,.1)",
    borderRight: "1px solid rgba(0,0,0,.1)"
}));

export const TableCellBorderL = styled(TableCell)(({ theme }) => ({
    borderLeft: "1px solid rgba(0,0,0,.1)"
}));

export const TableCellBorderR = styled(TableCell)(({ theme }) => ({
    borderRight: "1px solid rgba(0,0,0,.1)"
}));

export const TableRowShaded = styled(TableRow)(({ theme }) => ({
    backgroundColor: "rgba(117, 106, 78,.03)",
    height: "20px",
}))

export const TableRowCat = styled(TableRow)(({ theme }) => ({
    backgroundColor: "rgba(117, 106, 78,.1)",
    fontWeight: 'bold',
    typography: 'h3',
    color: 'primary',
}));
