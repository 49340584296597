import React, { useState, useEffect } from "react";
import {  Alert, Box, Input, Stack, Typography, Button, IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SyncIcon from '@mui/icons-material/Sync';
import { selectBudgetKey, selectBudgetId, selectProgressReports, selectSavingStatus, upsertBudgetIdAsync } from "../Common/stateSlices/budgetStateSlice";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { tabTypes } from "../../app/models";



export const MigrateSubmitBudgets = () => {
    const dispatch = useAppDispatch()
    const progressReports = useAppSelector(selectProgressReports)
    const noProgressReports = progressReports.length === 0;
    const savingStatus = useAppSelector(selectSavingStatus)
    const budgetSaved = savingStatus === "saved"

    const handleSubmit = () => {
        console.log("submit handled!")
        dispatch(upsertBudgetIdAsync({done: false}))
    }

    return (
        <>
            <Typography variant="h4" fontSize="3ch"> Submit Budgets</Typography>
            <br/>
            <Typography variant="body1">Please review to make sure all the correct information has been added, and then click 'submit' to save the budget.</Typography>
            <Stack gap={2} padding={2}>
                <Stack gap={1} direction={"row"}> <Typography>Blackbaud request sync</Typography> <CheckIcon color={"success"}/></Stack>
                <Stack gap={1} direction={"row"}> <Typography>Application Budget Reviewed</Typography> <CheckIcon color={"success"}/></Stack>
                {!noProgressReports &&
                    <Stack gap={1} direction={"row"}> <Typography>Progress Reports sync</Typography> <CheckIcon color={"success"}/></Stack>
                }
                <Stack gap={2} paddingLeft={2}>
                    {
                    progressReports.map( id => 
                        <Stack gap={1} direction={"row"} key={id}> 
                            <Typography>Progress Report {id}</Typography> 
                            <CheckIcon color={"success"}/>
                        </Stack>
                        )   
                    }
                    
                </Stack>

            </Stack>
            <Box marginLeft={3} width={300} gap={2}>
                <Button variant="contained" disabled={budgetSaved} onClick={handleSubmit}>Submit!</Button>
                {budgetSaved && <Alert sx={{marginTop: 2}} severity="success">Budget Saved!</Alert>}
            </Box>

        </>
    )


}