import * as React from "react";
import Typography from "@mui/material/Typography";
import { budgetTypes } from "../../../../app/budgetTypes";


export const BudgetNarrativeDescriptions = (props) => {
    const programArea: string = budgetTypes.RC1
    switch (programArea){
        case budgetTypes.RC1 :
            return (<Typography sx={{ fontStyle: "italic" }} variant="body1">
                Please enter any relevant narrative information that is needed to provide context to your progress report. Responses must be under 2,000 characters. <br/>
                If further explanation is needed please upload a file in your progress report in the Attachments tab under "Other Information" 
            </Typography>)
        case budgetTypes.CFWB1:
            return (<Typography sx={{fontStyle:"italic"}} variant="body1">
                Please enter any relevant narrative information that is needed to provide context to your progress report. Responses must be under 2,000 characters. <br/>
                If further explanation is needed please upload a file in your progress report in the Attachments tab under "Other Information" 
            </Typography>);
        default:
            return (<Typography sx={{ fontStyle: "italic" }} variant="body1">
                Please enter any relevant narrative information that is needed to provide context to your progress report. Responses must be under 2,000 characters. <br/>
                If further explanation is needed please upload a file in your progress report in the Attachments tab under "Other Information" 
            </Typography>)
    }
}