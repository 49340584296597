import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {BudgetCategoryRow} from "./BudgetCategoryRow";
import {
  BudgetID,
  AvailableBudgetTypes
} from "../../app/models";
import {
  setDefinitionHelpers
} from '../Common/stateSlices/appStateSlice';
import {
  selectExpenseBudgetItemsSumYear,
  selectExpenseTypesAvailable,
  selectExpenseBudgetItemsTotal,
  selectBudgetItemsCategoryTotal,
  selectIndirectDirectExpenseRatio,
  selectIndirectDirectExpenseRatioYear,
  selectBudgetItemsCategoryTotalYear,
} from '../Common/stateSlices/budgetStateSlice'
import { TableCellBorderLR, TableCellBorderL, TableCellBorderR } from '../Common/ThemedMuiComponents';

import { formatDollarValues, formatPercentageValues } from "../Common/helpers";

export const BudgetTable = (props) => {
  const {
    years = 3
  } = props;
  const dispatch = useAppDispatch();
  const budgetCategories = AvailableBudgetTypes(useAppSelector(selectExpenseTypesAvailable))
  const {revenue} = BudgetID
  const ExpenseBudgetItemsTotal = useAppSelector(selectExpenseBudgetItemsTotal);
  const ExpenseBudgetItemsSumYear = useAppSelector(selectExpenseBudgetItemsSumYear);
  const budgetItemsCategoryTotalYear = useAppSelector(selectBudgetItemsCategoryTotalYear);
  const budgetItemsCategoryTotal = useAppSelector(selectBudgetItemsCategoryTotal);
  const IndirectDirectExpenseRatio = useAppSelector(selectIndirectDirectExpenseRatio);
  const IndirectDirectExpenseRatioYear = useAppSelector(selectIndirectDirectExpenseRatioYear);
  const selectTypeExpenses = useAppSelector(selectExpenseTypesAvailable)
  const indirectExpenseAvailable = selectTypeExpenses.hasIndirectExpenses;
  const handleDefClickOpen = (helperKey: string = "giftInKind") => {
    dispatch(setDefinitionHelpers({ visible: true, key: helperKey }))
  }

  return (
    <TableContainer sx={{ mx: "auto", width: "calc(100vw*.9)" }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell sx={{ borderBottom: "0px solid", width: "35ch" }}/>
            <TableCell style={{ borderBottom: "0px solid" }} />
            {Array.apply(null, Array(years)).map((_, index) => {
              //Year Headers
              return (
                <TableCellBorderLR
                  key={index}
                  sx={{
                    borderBottom: "0px solid",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    fontSize: "1.1rem",
                    paddingBottom: 0
                  }}
                  align="center"
                  colSpan={2}
                >
                  Year {index + 1}
                </TableCellBorderLR>
              );
            })}
            <TableCellBorderL
              sx={{
                borderBottom: "0px solid",
                fontWeight: "bold",
                fontSize: "1.1rem",
                textTransform: "uppercase",
                minWidth: "28ch",
                maxWidth: "28ch",
                paddingBottom: 0
              }}
              align="center"
              colSpan={2}
            >
              Total
            </TableCellBorderL>
          </TableRow>
          <TableRow>
{/* Header: TDE and Other, 4 times */}
            <TableCell />
            <TableCell sx={{ width: "35ch" }} />
            <TableCell/>
            {Array.apply(null, Array(years)).map((_, index) => {
//TDE and Other Headers
              return (
                <React.Fragment key={index}>
                  <TableCellBorderL key={index + "TDE"} align="center">TDE {index === 0 ? 
                    <IconButton 
                      size="small" 
                      sx={{ position: 'relative', top: '-8px', padding: 0 }}
                      id="test"
                      onClick={() => handleDefClickOpen('TDERevenueExpense')}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                      </IconButton> : null}
                    </TableCellBorderL>
                  <TableCellBorderR key={index + "Other"} align="center">Other {index === 0 ? 
                    <IconButton 
                      size="small" 
                      sx={{ position: 'relative', top: '-8px', padding: 0 }}
                      onClick={() => handleDefClickOpen('OtherRevenueExpense')}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton> : null}
                  </TableCellBorderR>
                </React.Fragment>
              );
            })}
            <TableCellBorderL
              align="right"
              sx={{ minWidth: "14ch", maxWidth: "14ch" }}
            >
              TDE
            </TableCellBorderL>
            <TableCellBorderR
              align="right"
              sx={{ minWidth: "14ch", maxWidth: "14ch" }}
            >
              Other
            </TableCellBorderR>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgetCategories
            .map((row) => (
              <BudgetCategoryRow
                key={row.id}
                row={row}
                years={years}
              />
            ))}
            <TableRow >
              <TableCell sx={{ padding: "5px" }} />
            </TableRow> 
{/* Revenue Totals Row */}
          <TableRow id="totalRevenue" sx={{ backgroundColor: "rgba(117, 106, 78,.05)", borderTop: "2px solid rgba(0,0,0,.3)" }}>
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell
              style={{ borderBottom: "0px solid", fontSize: ".95rem"}}
              sx={{ width: "35ch" }}
            >
              <Typography>
                Total Revenue
              </Typography>
            </TableCell>
            <TableCell style={{ borderBottom: "0px solid" }} />
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <TableCellBorderLR
                  key={index}
                  style={{ borderBottom: "0px solid", fontStyle: "italic", textAlign: "center" }}
                  align="right"
                  colSpan={2}
                >
                  <Typography>
                    {formatDollarValues(budgetItemsCategoryTotalYear(index + 1, revenue))}
                  </Typography>
                </TableCellBorderLR>
              );
            })}
            <TableCellBorderL
              style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic", textAlign: "center" }}
              align="right"
              sx={{ minWidth: "14ch", maxWidth: "14ch" }}
              colSpan={2}
            >
              <Typography>
                {formatDollarValues(budgetItemsCategoryTotal(revenue))}
              </Typography>
            </TableCellBorderL>
          </TableRow>
{/* Total Expenses Row */}
          <TableRow id="totalExpenses" sx={{ backgroundColor: "rgba(117, 106, 78,.05)" }}>
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell
              style={{ borderBottom: "0px solid", fontSize:".95rem" }}
              sx={{ width: "35ch" }}
            >
              <Typography>
                Total Expenses
              </Typography>
            </TableCell>
            <TableCell style={{ borderBottom: "0px solid" }} />
            {Array.apply(null, Array(years)).map((_, index) => {
              return (
                <TableCellBorderLR
                  key={index}
                  style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                  align="center"
                  colSpan={2}
                >
                  <Typography>
                    {formatDollarValues(ExpenseBudgetItemsSumYear(index + 1))}
                  </Typography>
                </TableCellBorderLR>
              );
            })}
            <TableCellBorderL
              style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              colSpan={2}
              sx={{ minWidth: "28ch", maxWidth: "28ch" }}
            >
              <Typography>
                {formatDollarValues(ExpenseBudgetItemsTotal())}
              </Typography>
            </TableCellBorderL>
          </TableRow>
{/* Net Dollar Excess Row */}
          <TableRow 
            sx={{ backgroundColor: "rgba(117, 106, 78,.05)" }} 
            style={{ border: "0px solid" }}
            >
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell
              sx={{
                borderBottom: "0px solid",
                fontWeight:"bold",
                width: "35ch"
              }}
            >
              <Typography sx={{fontWeight: "bold"}}>
                Net Dollar Excess (Deficit)
              </Typography>
            </TableCell>
            <TableCell style={{ borderBottom: "0px solid" }} />
              {Array.apply(null, Array(years)).map((_, index) => {
                return (
                  <TableCellBorderLR
                    key={index}
                    sx={{ borderBottom: "0px solid"}}
                    align="center"
                    colSpan={2}
                  >
                    <Typography sx={{
                      fontWeight: "bold",
                      color: (budgetItemsCategoryTotalYear(index + 1, revenue) - ExpenseBudgetItemsSumYear(index + 1) < 0) ? "red" : "black"
}}>
                      {formatDollarValues(budgetItemsCategoryTotalYear(index + 1, revenue) - ExpenseBudgetItemsSumYear(index + 1))}
                    </Typography>
                  </TableCellBorderLR>
                );
              })}
              <TableCellBorderL
                sx={{
                  backgroundColor: "rgba(117, 106, 78,.03)",
                  borderBottom: "0px solid",
                  minWidth: "28ch",
                  maxWidth: "28ch"
                }}
                align = "center"
                colSpan = {2}
              >
              <Typography sx={{
                fontWeight: "bold",
                color: (budgetItemsCategoryTotal(revenue) - ExpenseBudgetItemsTotal() < 0) ? "red" : "black"
              }}>
                {formatDollarValues(budgetItemsCategoryTotal(revenue) - ExpenseBudgetItemsTotal())}
                </Typography>
              </TableCellBorderL>
            </TableRow>
{/* Indirect/Direct Ration Row */}
          {indirectExpenseAvailable &&
            <TableRow id="indirectDirectRatio" sx={{ backgroundColor: "rgba(117, 106, 78,.05)", borderBottom: "2px solid rgba(0,0,0,.3)" }}>
              <TableCell style={{ borderBottom: "0px solid" }} />
              <TableCell
                style={{ borderBottom: "0px solid", fontSize: ".95rem" }}
                sx={{ width: "35ch" }}
              >
                <Typography>
                  Indirect/Direct Ratio
                </Typography>
              </TableCell>
              <TableCell/>
              {Array.apply(null, Array(years)).map((_, index) => {
                console.log(years,_, index)
                return (
                  <TableCellBorderLR
                    key={index}
                    style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                    align="center"
                    colSpan={2}
                  >
                    <Typography>
                      {formatPercentageValues(IndirectDirectExpenseRatioYear(index + 1))
                      }
                    </Typography>
                  </TableCellBorderLR>
                );
              })}
              <TableCellBorderL
                style={{ backgroundColor: "rgba(117, 106, 78,.03)", borderBottom: "0px solid", fontStyle: "italic" }}
                align="center"
                colSpan={2}
                sx={{ minWidth: "28ch", maxWidth: "28ch" }}
              >
                <Typography>
                  {formatPercentageValues(IndirectDirectExpenseRatio())
                  }
                </Typography>
              </TableCellBorderL>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};
