export enum budgetTypes {
    HC1 = "HC1",
    RC1 = "RC1",
    CFWB1 = "CFWB1",
    SI1 = "SI1",
    HE1 ="HE1",
    GENERAL = "GENERAL"
}

export interface iProgramAreas {
    label: string,
    id: budgetTypes
}

export const programAreasList: iProgramAreas[] = [
    { label: "Health Care",  id: budgetTypes.HC1 },
    { label: "Rural Church",  id: budgetTypes.RC1 },
    { label: "Child and Family Well Being",  id: budgetTypes.CFWB1 },
    { label: "Special Initiatives",  id: budgetTypes.SI1 },
    { label: "Higher Education",  id: budgetTypes.HE1 },
    { label: "General",  id: budgetTypes.GENERAL },
]