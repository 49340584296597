import { ReactElement } from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel'
import Box from "@mui/material/Box";


interface ILoadingIcon {
    state: "idle" | "loading" | "failed" | "loaded" | "new" | "none",
    loadingTarget: string
}

export const LoadingIcons =  ({state, loadingTarget}: ILoadingIcon) => {
    const loaded = state === "loaded";
    const title = loaded
        ? `${loadingTarget}: Loaded!`
        : `Loading ${loadingTarget}`;

    const icons: {loaded:ReactElement, failed:ReactElement} = 
    {
        loaded: <CheckIcon sx={{ color: 'green' }} />,
        failed: <CancelIcon sx={{ color: 'red' }} />
    } 

    return (
        <Box display="flex" alignItems="center" gap={2}>
            { icons[state] || <CircularProgress size={20} /> }
            <Typography>{title}</Typography>
        </Box>
    )
}

