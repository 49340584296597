import React, { useState, useEffect } from "react";
import {  Alert, Box, Input, Stack, Typography, Button, IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SyncIcon from '@mui/icons-material/Sync';
import { selectBudgetKey, selectBudgetId } from "../Common/stateSlices/budgetStateSlice";
import { addMigrationTab, selectMigrationTabs, setMigrationCurrentTab, selectMigrationCurrentTab } from "../Common/stateSlices/appStateSlice";
import {
    fetchBlackbaudRequestRefAsync, 
    fetchBlackbaudPaymentRefAsync,
    selectPaymentsLoading,
    selectRequestStatus, 
    selectRequestId
} from "../Common/stateSlices/bbgDataSlice"
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { tabTypes } from "../../app/models";


export const MigrateConnectBbg = (props) => {
    const [showInfo, setShowInfo] = React.useState(false);
    const dispatch = useAppDispatch()
    const requestLoadingStatus = useAppSelector(selectRequestStatus)
    const budgetId = useAppSelector(selectBudgetId)
    const budgetKey = useAppSelector(selectBudgetKey)
    const migrationTabs = useAppSelector(selectMigrationTabs)
    const budgetExists = migrationTabs.includes(tabTypes.budget)
    const requestId = useAppSelector(selectRequestId);
    const paymentsLoadingStatus = useAppSelector(selectPaymentsLoading);
    const migrationCurrentTab = useAppSelector(selectMigrationCurrentTab)
    
    console.log(requestLoadingStatus)

    useEffect(()=> {
        if(requestLoadingStatus === "loaded"){
            dispatch(fetchBlackbaudPaymentRefAsync(requestId))
        }
    }, [requestLoadingStatus])

    function checkConnection(){
        dispatch(fetchBlackbaudRequestRefAsync(budgetId))
    }

    function copyToClipboard(txt){
        navigator.clipboard.writeText(txt)
    }

    function handleGoToBudget(){
        if(!budgetExists){
            dispatch(addMigrationTab({tab: tabTypes.budget, navigate: true}))
            dispatch(setMigrationCurrentTab(migrationCurrentTab + 1))
        }else{
            dispatch(setMigrationCurrentTab(migrationTabs.indexOf(tabTypes.budget)))
        }

    }

    let requestStatusIcon, paymentsStatusIcon 
    
    switch(requestLoadingStatus){
        case "idle": 
            requestStatusIcon = null;
            break;
        case "failed":
            requestStatusIcon = <PriorityHighIcon/>
            break;
        case "loading":
            requestStatusIcon = <SyncIcon/>
            break;
        case "loaded":
            requestStatusIcon =  <CheckIcon/>
            break;
        default:
            requestStatusIcon = null
    }
    switch(paymentsLoadingStatus){
        case "idle": 
            paymentsStatusIcon = null;
            break;
        case "failed":
            paymentsStatusIcon = <PriorityHighIcon/>
            break;
        case "loading":
            paymentsStatusIcon = <SyncIcon/>
            break;
        case "loaded":
            paymentsStatusIcon=  <CheckIcon/>
            break;
        default:
            paymentsStatusIcon = null
    }

    return (
        <>
            <Typography variant="h4" fontSize="3ch"> Connect to Blackbaud</Typography>

            <Typography variant="body1">Please go to the appropriate Blackbaud request and add the following values into the appropriate fields. You can find these in your Request in Blackbaud under General: Request. </Typography>

            <Box marginTop="3ch" marginBottom={"5ch"}>
                    <Stack width={670} justifyContent={"space-between"} marginBottom={3} direction={"row"} alignItems={"center"}>
                        <Typography>Budget Application ID: {budgetId}</Typography>
                        <Button size="small" variant="contained" startIcon={<ContentCopyIcon/>} onClick={()=>copyToClipboard(budgetId)}>Copy to Clipboard</Button>
                        </Stack>
                    <Stack width={670} direction={"row"} justifyContent={"space-between"} gap={3} alignItems={"center"}>
                        <Typography>Budget Key: {budgetKey} </Typography>
                        <Button size="small" color="primary" variant="contained" startIcon={<ContentCopyIcon/>}  onClick={()=>copyToClipboard(budgetKey)}>Copy to Clipboard</Button>
                        </Stack>
            </Box>
            <Stack marginBottom={2} direction="row" gap={2}>
                <Button variant="contained" endIcon={requestStatusIcon} onClick={checkConnection} disabled={requestLoadingStatus === "loaded"}>Check connection</Button>
                <Button variant="contained" endIcon={paymentsStatusIcon} disabled={paymentsLoadingStatus === "loaded"}>Gather Payments</Button>
                <Button variant="contained" disabled={paymentsLoadingStatus !== "loaded"} onClick={handleGoToBudget}>Go to Application Budget</Button>
            </Stack>
            {requestLoadingStatus === "failed" && <Alert severity="error"> Attempt to connect unsuccessful! Please make sure you have copied the BudgetID and Budget Key into the correct location AND saved.</Alert>}
            {/* <Typography fontStyle="italic"> Please provide the REQUEST ID below</Typography>
            <br/>
            <Input type="number" placeholder="Request Id"></Input> <Button onClick={()=> setShowInfo(true)}>Find Request</Button>
            <br/>
            {showInfo &&
                <Box marginTop="3ch" >
                    <Typography>Organization: Wayne Foundation</Typography>
                    <Typography>Project Title: Example</Typography>
                    <Typography>Grant ID: 1234A</Typography>
                    <Button>Add Budget to Blackbaud</Button>
                </Box>} */}
            {/* <Button variant="contained" onClick={handleGoToBudget}>Go to the initial budget</Button> */}
        </>
    )


}