import { createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  RequirementTypes,
  iAppStates,
  tabTypes
} from "../../../app/models";
import { RootState } from "../../../app/store";



const initialState: iAppStates = {
  narrativeOpen: false,
  definitionHelpers: {
    visible: false,
    key: ""
  },
  requirementSuccessMsg: false,
  disableSubmit: false,
  printScreenOpen: false,
  printScreenId: null,
  printScreenReqType: null,
  migrationTabs: [tabTypes.start]
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.


export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    setNarrativeOpen: (state, action: PayloadAction<boolean>) => {
      state.narrativeOpen = action.payload
    },
    setPrintScreenOpen: (state, action: PayloadAction<{id: number, reqType: RequirementTypes | null}>) => {
      state.printScreenOpen = true
      state.printScreenId = action.payload.id
      state.printScreenReqType = action.payload.reqType
    },
    setPrintScreenClosed: (state) => {
      state.printScreenOpen = false
    },
    setDefinitionHelpers: (state, action: PayloadAction<{visible: boolean, key: string}>) =>{
      state.definitionHelpers = action.payload;
    },
    setDisableSubmit: (state, action: PayloadAction<boolean>) =>{
      state.disableSubmit = action.payload;
    },
    setRequirementSuccessMsg: (state, action: PayloadAction<boolean>) => {
      state.requirementSuccessMsg = action.payload;
    },
    setApplicationSuccessMsg: (state, action: PayloadAction<boolean>) =>{
      state.applicationSuccessMsg = action.payload;
    },
    setMigrationCurrentTab: (state, action: PayloadAction<number>) =>{
      state.migrationCurrentTab = action.payload
    },
    addMigrationTab: (state, action: PayloadAction<{tab: string | tabTypes, navigate: boolean}>) =>{
      const newIndex = state.migrationTabs.length
      state.migrationTabs = [...state.migrationTabs,  action.payload.tab];
      if(action.payload.navigate){
        state.migrationCurrentTab = newIndex
      } 
    },

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

export const {
  setDefinitionHelpers,
  setApplicationSuccessMsg,
  setNarrativeOpen,
  setPrintScreenOpen,
  setPrintScreenClosed,
  setRequirementSuccessMsg,
  setDisableSubmit,
  addMigrationTab,
  setMigrationCurrentTab
} = appStateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

                  /////// COMMON REPORT SELECTORS /////////

export const selectDefinitionHelpers = (state: RootState) =>
  state.appState.definitionHelpers;
  // TODO 

export const selectNarrativeOpen = (state: RootState) =>
  state.appState.narrativeOpen
export const selectPrintScreenOpen = (state: RootState) =>
  state.appState.printScreenOpen
export const selectPrintScreenInfo = (state: RootState) =>
  ({
    printId: state.appState.printScreenId,
    printReqType: state.appState.printScreenReqType

  })

export const selectRequirementSuccessMsg = (state: RootState) =>
  state.appState.requirementSuccessMsg;
export const selectDisableSubmit = (state: RootState) =>
  state.appState.disableSubmit
export const selectApplicationSuccessMsg = (state: RootState) =>
  state.appState.applicationSuccessMsg;
export const selectMigrationTabs = (state: RootState) =>
  state.appState?.migrationTabs;
export const selectMigrationCurrentTab = (state: RootState) =>
  state.appState?.migrationCurrentTab || 0;




export default appStateSlice.reducer;

