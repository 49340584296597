import React, { useState } from "react";
import {  Box, Button, Input, Typography } from "@mui/material";
import { ProgressReportTable } from "../requirements-connector/requirements-connector-parts/progress-report-table/ProgressReportTable";
import { BudgetTable } from "../budget-table/BudgetTable";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectTotalYears } from "../Common/stateSlices/budgetStateSlice";
import { tabTypes } from "../../app/models";
import { selectMigrationTabs, addMigrationTab, setMigrationCurrentTab } from "../Common/stateSlices/appStateSlice";

export const MigrateProgressReport = (props: {progressReportId: number}) => {
    const {progressReportId} = props;
    const dispatch = useAppDispatch()
    const migrationTabs = useAppSelector(selectMigrationTabs)
    const progressReportsExists = migrationTabs.includes(tabTypes.progressReports)

    function handleGoToBudget(){
        if(!progressReportsExists){
            // dispatch(addMigrationTab(tabTypes.progressReports))
        }else{
            dispatch(setMigrationCurrentTab(migrationTabs.indexOf(tabTypes.progressReports)))
        }
    }

    const handleAddSubmit = () => {
        dispatch(addMigrationTab({tab: tabTypes.submitBudgets , navigate: true}))
    }

    return (
        <>
            <Typography variant="h4" fontSize="3ch"> Review Progress Report Budget</Typography>
            <Box  marginTop="3ch" >
                <Typography fontStyle="italic">Please fill out the application budget or perform any review and/or corrections needed to make the budget correct.</Typography>
                <ProgressReportTable progressReportId={progressReportId} disabled={false}/>
                <Box marginTop={5} display="flex" width={"100%"} justifyContent={"flex-end"}>
                    {/* <Button variant="contained" onClick={handleGoToBudget}>Go to Progress Reports</Button> */}
                    <Button variant="contained" onClick={handleAddSubmit}>Go to Submit Migrated budget</Button>
                </Box>
                
            </Box>
            
        </>
    )


}