import { Typography } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import { budgetTypes } from "../../../app/budgetTypes";

import { selectBudgetType } from "../../Common/stateSlices/budgetStateSlice";
import { FaqType } from "../../../app/models";

interface QuestionAnswerFormat{
        Q: string;
        A: string;
}

const standardQuestions: QuestionAnswerFormat[] = [
    { 
        Q: "What type of device can I use to complete the form?",
        A: "You can use Windows PC or a Mac, but the form cannot be completed on a phone." 
    },
    {
        Q: "Why is the budget narrative not printing?",
        A: "The budget narrative will not print if the field is empty. If the budget narrative is not empty and will not print, please contact us."
    },
    {
        Q: "Can I send this link to a colleague?",
        A: "No, you will need to transfer the application in the GrantRequest.com portal, and then they will be able to open the budget in the 'Project/Program' screen, with the 'Enter Budget Details' button. "
    },
    {
        Q: "Can I make changes to the budget after I have already uploaded it in the Attachments tab?",
        A: "Yes, you can make changes to your budget and re-upload a new PDF document, until the application has been submitted."
    },
    {
        Q: "If I save a draft of the grants budget, where does that save?",
        A: "Once you have saved a draft, and have closed out of the budget portal window, in the application portal, select the Program/Project tab, scroll down, select Edit Budget Detail, the budget will open in a separate window."
    },
    {
        Q: "If I click Add to Application, without clicking on SAVE, will that still save or do I need to click SAVE, then ADD TO APPLICATION?",
        A: "Yes, clicking Add to Application will also save your budget. "
    },
    {
        Q: "In the grants budget portal, I clicked “Add to Application” and nothing happened.",
        A: "The information that you included in the budget has been added to your application, please make sure that once you have closed the grants budget portal window, you click “Save and Finish Later”, “Next”, or “Review and Submit”, to successfully add the budget information to your application."
    },

]

function ProjectAreaItems(id): QuestionAnswerFormat[]{
    switch(id){
        case budgetTypes.CFWB1: 
            return ( [
                {
                    Q:"Is there a limit on the amount of support that can be requested?", 
                    A: "No.  The request should reflect the applicant’s the vision and actual cost of the project.  However, the Endowment must balance several factors when determining the amount of support including our available resources, grantmaking priorities and competing requests."
                },
                {
                    Q:"Are capital projects (bricks and mortar) eligible for support?", 
                    A: "No."
                },
                {
                    Q:"Are applicants limited to five years of support?", 
                    A: "No.  We will consider additional support if progress, available resources, grantmaking priorities and competing requests warrant continued support."
                }

            
            ]
            );
        default: return []
    }
}
function getQuestionsByFaqType (faqType: FaqType): QuestionAnswerFormat[]{
    switch(faqType){
        case FaqType.admin: 
            return ( [
                {
                    Q:"Is it an Admin question?", 
                    A: "Yes."
                }
            ]
            );
        case FaqType.budget:
            return ( [
                {
                    Q:"Is it a budget question?", 
                    A: "Yes."
                }
            ]
            );
        case FaqType.requirement:
            return ( [
                {
                    Q:"Is it a requirement question?", 
                    A: "Yes. "
                }
            ]
            );
        default: return []
    }
}

const QuestionAnswer = (props: {Q: string, A: string}) => {
    const {Q, A} = props;
    return (
        <>
            <Typography sx={{fontWeight: 'bold'}}>
                Q. {Q}
            </Typography>
            <Typography sx={{paddingLeft: '1ch', marginBottom: '1ch'}}>
                A. {A}
            </Typography>
        </>
    )
}


// export const Faqs = (props: { budgetType: string }) => {
export const Faqs = (props: {faqType: FaqType}) => {
    const {faqType} = props; 
    const BudgetType = useAppSelector(selectBudgetType);
    // const { budgetType } = props;
    const allQuestions = [...standardQuestions, ...ProjectAreaItems(BudgetType), ...getQuestionsByFaqType(faqType)];
    return (            
        <>
        {allQuestions.map( item => <QuestionAnswer key={item.Q} Q={item.Q} A={item.A}/> )}
        <QuestionAnswer Q = "What type of device can I use to complete the form?" A = "You can use Windows PC or a Mac, but the form cannot be completed on a phone." />
        </>
        )

}
