import React from "react";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Grid from '@mui/material/Grid';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle"

import {
  setDefinitionHelpers
} from '../../../Common/stateSlices/appStateSlice';
import {
  selectCurrentProgressReport,
} from '../../../Common/stateSlices/budgetStateSlice';
import {
  selectBlackbaudRequirementData,
} from '../../../Common/stateSlices/bbgDataSlice';

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {RequirementsDateInput}  from './RequirementsDateInput'
import {yearlyUpdateCategories} from '../../../../app/models'


export const RequirementsReportingPeriod = () => {

    const dispatch = useAppDispatch();
    const handleDefClickOpen = (helperKey: string = "giftInKind") => dispatch(setDefinitionHelpers({ visible: true, key: helperKey }))
    const getRequestDates = useAppSelector(selectBlackbaudRequirementData);
    const currentProgressReport = useAppSelector(selectCurrentProgressReport)
    const requestDates: {dates:{}} = getRequestDates(currentProgressReport)
    const requestDatesArr: any[] = Object.values(requestDates.dates)
    const reqdDatesMissing: boolean = requestDatesArr.includes(null)

    return (
        <Stack  alignItems="center">
            <Grid sx={{mt: "2ch"}} wrap="wrap" justifyContent="center"  container spacing={2}>
                {yearlyUpdateCategories.map((el,index) => {
                    return (
                        <React.Fragment key={index}>

                            <Card  sx={{mb: "16px", mr: "12px", height: "34ch"}}>
                                <CardHeader
                                    title={el.categoryName}
                                    action={<IconButton 
                                                size="small" 
                                                sx={{ top: '-8px', padding: 0 }}
                                                    onClick={() => {handleDefClickOpen(el.categoryId)}}
                                            >
                                                <InfoOutlinedIcon fontSize="small" />
                                            </IconButton>}
                                    sx={{ display: 'inline-flex', textAlign: "center" }}
                                />
                                <CardContent>
                                    <Stack>
                                            <RequirementsDateInput progressReportId={currentProgressReport} yearlyUpdateCategoryId={el.categoryId} bbgDates={requestDates}/>
                                    </Stack>  
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    )
                })} 
            </Grid>
            {reqdDatesMissing            
                ?<Alert sx={{maxWidth: "50%"}} severity="warning">
                    <AlertTitle> Warning </AlertTitle>
                    Please ask your staff member contact to update the beginning or ending dates for progress report {currentProgressReport} before you submit. Please note you will not be able to see the Endowment's contributions in the revenue line for this time period until this is resolved.
                </Alert>
                : null
            }
        </Stack>

    )
}