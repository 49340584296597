import * as React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { BudgetNarrativeDescriptions } from "./BudgetNarrativeDescriptions";
import {
    setBudgetNarrative,
    selectBudgetNarrative
} from '../Common/stateSlices/budgetStateSlice';


export const BudgetNarrative = (props) => {
    const maxCharCount = 2000;
    const dispatch = useAppDispatch();
    const BudgetNarrative = useAppSelector(selectBudgetNarrative);
    const BudgetLength = BudgetNarrative.length
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
        event.target.value.length < (maxCharCount + 1) && dispatch(setBudgetNarrative(event.target.value))
    }

    return(
        <Box component="form" border="1px solid rgba(0,0,0,.1)" padding="15px" >
            <Typography variant="subtitle1" sx={{fontSize: "2.3ch", lineHeight: "1.2", marginBottom: ".2ch"}}>Budget Narrative</Typography>
            {/* <Typography variant="body1">Please enter any relevant narrative data that is needed to provide context to your budget.</Typography> */}
            <Typography sx={{ fontStyle: "italic", color: 'red', fontSize: '1.5ch' }} variant="body1">*Not required</Typography>
            <BudgetNarrativeDescriptions />
            <TextField 
                onChange={handleChange}
                value={BudgetNarrative}
                rows={12} 
                multiline 
                sx={{paddingTop: "3ch", width: "100%"}}
            />
            <Box display="flex" justifyContent={"flex-end"} width="100%">
                <Typography 
                    paddingRight={"10px"} 
                    paddingTop={"8px"} 
                    variant="caption"
                    color={BudgetLength === 1000 ? "red" : "grey"}
                >
                    {BudgetLength.toLocaleString()}/{maxCharCount.toLocaleString()}
                </Typography>
            </Box>

        </Box>
    )
}