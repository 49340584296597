import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Alert } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow"

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  DollarType,
  BudgetID, 
} from "../../app/models";
import { 
  selectFinalReportCategorySubTotal,
  selectBudgetItemsCategorySubTotal
} 
from "../Common/stateSlices/budgetStateSlice";
import { 
  setDisableSubmit,
  selectDisableSubmit
} 
from "../Common/stateSlices/appStateSlice";
import { formatDollarValues } from "../Common/helpers";
import { TableRowCat } from "../Common/ThemedMuiComponents";


export const FinalReportSubmit = () => {
  const budgetItemsCategorySubTotal = useAppSelector(selectBudgetItemsCategorySubTotal)
  const finalReportCategorySubTotal = useAppSelector(selectFinalReportCategorySubTotal);
  const disableSubmit = useAppSelector(selectDisableSubmit)
  console.log(disableSubmit)
  const dispatch = useAppDispatch();

  const projectBudgetRevenueTde = budgetItemsCategorySubTotal(BudgetID.revenue, DollarType.TDE)
  const projectBudgetRevenueOther = budgetItemsCategorySubTotal(BudgetID.revenue, DollarType.Other)
  const projectBudgetExpensesTde = budgetItemsCategorySubTotal(BudgetID.programExpense, DollarType.TDE) + 
                                    budgetItemsCategorySubTotal(BudgetID.indirectExpense, DollarType.TDE)
  const projectBudgetExpensesOther = budgetItemsCategorySubTotal(BudgetID.programExpense, DollarType.Other) + 
                                    budgetItemsCategorySubTotal(BudgetID.indirectExpense, DollarType.Other)
  const projectActualRevenueTde = finalReportCategorySubTotal(BudgetID.revenue, DollarType.TDE)
  const projectActualRevenueOther = finalReportCategorySubTotal(BudgetID.revenue, DollarType.Other)
  const projectActualExpensesTde = finalReportCategorySubTotal(BudgetID.programExpense, DollarType.TDE) +
                                  finalReportCategorySubTotal(BudgetID.indirectExpense, DollarType.TDE)
  const projectActualExpensesOther = finalReportCategorySubTotal(BudgetID.programExpense, DollarType.Other) + 
                                    finalReportCategorySubTotal(BudgetID.indirectExpense, DollarType.Other)
  const revenuesTDE = { actual: projectActualRevenueTde,  budget: projectBudgetRevenueTde }
  const revenuesOther = { actual: projectActualRevenueOther,  budget: projectBudgetRevenueOther }
  const expensesTDE = { actual: projectActualExpensesTde,  budget: projectBudgetExpensesTde }
  const expensesOther = { actual: projectActualExpensesOther,  budget: projectBudgetExpensesOther }
  const surplusDeficitTDE = {
    actual: projectActualRevenueTde - projectActualExpensesTde,
    budget: projectBudgetRevenueTde - projectBudgetExpensesTde
  }
  const surplusDeficitOther = {
    actual: projectActualRevenueOther - projectActualExpensesOther,
    budget: projectBudgetRevenueOther - projectBudgetExpensesOther
  }

  const tdeDeficitOrSurplus = surplusDeficitTDE.actual !== 0;
  dispatch(setDisableSubmit(tdeDeficitOrSurplus))

    return (
      <Box
        component="form"
        sx={{
          // "& .MuiTextField-root": { m: 1, width: "50ch" },
          mx: "auto",
          width: "90vw",

        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h1" sx={{ fontSize: "4ch", fontWeight: 400 }}>Submit your final report budget</Typography>
        {/* including "Current Budget" */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="h4" component="h4" align="center" color="primary" sx={{ paddingTop: "30px", fontSize: "4ch", paddingBottom: "30px" }}>
                  Total Project Budget
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" component="h4" align="center" color="primary" sx={{ paddingTop: "30px", fontSize: "4ch", paddingBottom: "30px" }}>
                  Total Project Actual
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" component="h4" align="center" color="primary" sx={{ paddingTop: "30px", fontSize: "4ch", paddingBottom: "30px" }}>
                  Actual (Over)/ Under Budget
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {createCategory('Total Revenue', revenuesTDE, revenuesOther)}
            {createCategory('Total Expenses', expensesTDE, expensesOther)}
            <br/>
            {createCategory('Total Surplus/Deficit', surplusDeficitTDE, surplusDeficitOther)}
          </TableBody>
        </Table>
        { tdeDeficitOrSurplus 
          ? <Alert sx={{mt: "1ch"}} severity="error">Every project should end with no surplus or deficit of Duke Endowment funds. If there is an limiting reason for the suplus or deficit, please reach out to your contact at the Endowment to discuss a resolution.</Alert>
          : null
        }
      </Box>
    );
  }

interface iBudgetActualVals{budget: number, actual: number}

function createCategory(name, tde: iBudgetActualVals, other: iBudgetActualVals ){
  const totalBudget = tde.budget + other.budget
  const totalActual = tde.actual + other.actual
  const totalOverUnder = totalActual-totalBudget

  const TdeActualDeficit = name === "Total Surplus/Deficit" && tde.actual !== 0

  return (
    <>
      <TableRowCat >
        <TableCell>
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            {name}
          </Typography>
        </TableCell>
        {createTableCells(totalBudget, 'h6', {fontWeight: 'bold'})}
        {createTableCells(totalActual, 'h6', { fontWeight: 'bold' })}
        {createTableCells(totalOverUnder, 'h6', { fontWeight: 'bold' })}
      </TableRowCat>
      <TableRow >
        <TableCell sx={{ paddingLeft: "5ch" }}>
          <Typography variant="h6" color="primary">
            The Duke Endowment
          </Typography>
        </TableCell>
        {createTableCells(tde.budget, 'h6')}
        {createTableCells(tde.actual, 'h6', {}, TdeActualDeficit?"red":"primary")}
        {createTableCells(tde.actual-tde.budget, 'h6')}
      </TableRow>
      <TableRow >
        <TableCell sx={{ paddingLeft: "5ch" }}>
          <Typography variant="h6" color="primary">
            Other
          </Typography>
        </TableCell>
        {createTableCells(other.budget, 'h6')}
        {createTableCells(other.actual, 'h6')}
        {createTableCells(other.actual - other.budget, 'h6')}
      </TableRow>
    </>
    )
}

function createTableCells(dollarVal, variant, cellStyle = {}, cellColor = "primary") {
  return (
    <TableCell>
      <Typography
        variant={variant}
        color= {cellColor}
        textAlign={'end'}
        sx={cellStyle}
      >
        {formatDollarValues(dollarVal)}
      </Typography>
    </TableCell>
  )
}
