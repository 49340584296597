import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectTotalYears,
  selectProjectTitle,
  setProjectTitle,
  setTotalYears,
  fetchBudgetIdAsync,
  upsertBudgetIdAsync,
  selectSavingStatus
} from "../Common/stateSlices/budgetStateSlice";
import {
  selectRequestStatus,
  fetchBlackbaudRequestRefAsync
} from "../Common/stateSlices/bbgDataSlice";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export interface ApplicationAdminState {
  value: number;
}
export interface ApplicationAdminState {
  value: number;
}

const currencies = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

function BudgetSummaryDetails() {
  const totalYears = useAppSelector(selectTotalYears);
  const projectTitle = useAppSelector(selectProjectTitle);
  const dispatch = useAppDispatch();

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "50ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={8}>
        <TextField
          required
          id="outlined-required"
          label="Project Title"
          value={projectTitle || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setProjectTitle(event.target.value));
          }}
        />
        <TextField
          id="outlined-select-currency"
          required
          select
          label="Select Number of Year"
          defaultValue=""
          value={totalYears || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setTotalYears(Number(event.target.value)));
          }}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Box>
  );
}

function BudgetDetails() {

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "50ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <img
        style={{ margin: "8px", width: "50%" }}
        src="/budget-sheet.png"
        alt="Budget Sheet"
      />
      <Typography gutterBottom variant="h6" component="div" sx={{ mt: 4 }}>
        REVENUE FOR ALL SOURCES
      </Typography>
      <TextField
        required
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        id="outlined-required"
        label="Total Revenue for All Sources"
        value={""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          console.log(event);
        }}
      />
      <Typography gutterBottom variant="h6" component="div" sx={{ mt: 4 }}>
        PROGRAM EXPENSES
      </Typography>
      <TextField
        required
        type="number"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        id="outlined-required"
        label="Total Program Expenses"
        value={""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          console.log(event);
        }}
      />
      <Typography gutterBottom variant="h6" component="div" sx={{ mt: 4 }}>
        CAPITAL EXPENSES
      </Typography>
      <TextField
        required
        type="number"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        id="outlined-required"
        label="Total Capital Expenses"
        value={""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          console.log(event);
        }}
      />
    </Box>
  );
}

function BudgetReview() {
  const projectTitle = useAppSelector(selectProjectTitle);
  const totalYears = useAppSelector(selectTotalYears);
  // const netAmount = useAppSelector(selectNetAmount);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "50ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Typography gutterBottom variant="subtitle1" component="div">
        Project Title: <b>{projectTitle}</b>
      </Typography>
      <Typography gutterBottom variant="subtitle1" component="div">
        Total Years: <b>{totalYears}</b>
      </Typography>
      <Typography gutterBottom variant="subtitle1" component="div">
        Net Amount: <b>$10000000</b>
      </Typography>
    </Box>
  );
}

export const ApplicationAdminEdit = () => {
  const [activeStep, setActiveStep] = React.useState(2);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const requestStatus = useAppSelector(selectRequestStatus);
  const savingStatus = useAppSelector(selectSavingStatus);
  useEffect(() => {
    dispatch(fetchBlackbaudRequestRefAsync(id));
    dispatch(fetchBudgetIdAsync({id, admin: true, budgetKey: "not needed"}));
  }, [id]);

  const handleAddToSave = async () => {
    await dispatch(upsertBudgetIdAsync({ done: true }));
    navigate(`/budget/application/${id}`)
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const steps = [
    {
      label: "Provide your budget summary details",
      description: <BudgetSummaryDetails />,
    },
    {
      label: "Create your budget",
      description: <BudgetDetails />,
    },
    {
      label: "Review your budget & Add it to your application",
      description: <BudgetReview />,
    },
  ];

  return (
    <>
      <Card variant="outlined">
        {savingStatus === "saving" ? (
          <LinearProgress />
        ) : (
          <Box sx={{ height: "4px" }} />
        )}
        <CardHeader
          title="The Duke Endowment Budget Form"
          subheader="Please follow the steps to complete your budget form."
        />
        <CardContent>
          <Box sx={{ width: "100%" }}>
            {requestStatus !== "loaded" ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress sx={{ margin: "auto" }} />
              </Box>
            ) : (
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        index === 2 ? (
                          <Typography variant="caption">Final step</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      {step.description}
                      <Box sx={{ mb: 2, mt: 4 }}>
                        <div>
                          <Button
                            size="large"
                            variant="contained"
                            onClick={handleAddToSave}
                            disabled={savingStatus === "saving"}
                            sx={{
                              mt: 1,
                              mr: 1,
                              display:
                                index !== steps.length - 1 ? "none" : null,
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            size="large"
                            variant="contained"
                            onClick={handleNext}
                            sx={{
                              mt: 1,
                              mr: 1,
                              display:
                                index === steps.length - 1 ? "none" : null,
                            }}
                          >
                            Continue
                          </Button>
                          <Button
                            size="large"
                            onClick={handleBack}
                            sx={{
                              mt: 1,
                              mr: 1,
                              display: index === 0 ? "none" : null,
                            }}
                          >
                            Back
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
