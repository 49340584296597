import Typography from '@mui/material/Typography';
import { useAppSelector } from "../../../app/hooks";
import { budgetTypes } from "../../../app/budgetTypes";
import { selectBudgetType } from  "../../Common/stateSlices/budgetStateSlice";
import { FaqType } from "../../../app/models";
  
function supportStaff(id:budgetTypes) {
    const staff = {
        CFWB1: { name: "Kate Gaskin", email: "kgaskin@tde.org", phone: "704-969-2140" },
        HC1: { name: "Julie Hale", email: "jhale@tde.org", phone: "704-969-2107" },
        HE1: { name: "Ashleigh Allessio", email: "aallessio@tde.org", phone: "704-969-2135" },
        SI1: { name: "Kate Gaskin", email: "kgaskin@tde.org", phone: "704-969-2140" },
        RC1: { name: "Jackie Lademann", email: "jlademann@tde.org", phone: "704-927-2268" },
    }

    return staff[id]
}

export const AdminContacts = (props: {faqType: FaqType}) => {
    const {faqType} = props; 
    const budgetType = useAppSelector(selectBudgetType);
    let BudgetType;
    faqType === FaqType.budget && (BudgetType = budgetType);
    faqType === FaqType.requirement && (BudgetType = budgetType);
    const {name, email, phone} = supportStaff(BudgetType);
    const hrefTarget = `mailto:${email}`
    return (            
        <>
            <Typography variant="h4" sx={{color: 'black', fontSize: '2.5ch'}}>
                {name}
            </Typography>
            <Typography sx={{marginLeft: "2ch" }}>
                Phone: {phone}
            </Typography>
            <Typography sx={{ marginLeft: "2ch" }}>
                Email: <a href={hrefTarget} target="_blank">{email}</a>
            </Typography>
        </>
        )

}