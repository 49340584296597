import React, { useState, useEffect }  from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Alert } from '@mui/material';

import { useAppSelector } from "../../app/hooks";
import {
  DollarType,
  BudgetID, 
  yearlyUpdateCategories, //list categoryId for budgetItemsCategoryTotal
  yearlyUpdateId
} from "../../app/models";

import { 
  selectProgressReportItemsCategorySubTotal,
  selectCurrentProgressReport,
  selectProgressReportItemsRevenueSubTotal,
  selectProgressReportItemsCategoryNetSubTotal,
  selectProgressReportItemsExpensesSubTotal,
} 
from "../Common/stateSlices/budgetStateSlice";

import { formatDollarValues } from "../Common/helpers";
import { TableRowCat } from "../Common/ThemedMuiComponents";

interface warningItem {
  categoryName?: string, 
  netAmount?:number, 
  carryOver?:number, 
  totalRevenue?:number
}
let warningListCopy : warningItem[]= [];

const currentAndFutureYearlyCats = yearlyUpdateCategories
  .filter(e => e.categoryId !== yearlyUpdateId.current)

export const ProgressReportSubmit = () => {
    const progressReportItemsCategorySubTotal = useAppSelector(selectProgressReportItemsCategorySubTotal);
    const progressReportItemsCategoryNetSubTotal = useAppSelector(selectProgressReportItemsCategoryNetSubTotal)
    const progressReportId = useAppSelector(selectCurrentProgressReport);
    const progressReportItemsRevenueSubTotal = useAppSelector(selectProgressReportItemsRevenueSubTotal)

    const [warningList, setWarningList] = useState<warningItem[]>([]);
  
  function getCategorySubTotalsFor(category: string, budgetType: yearlyUpdateId, dollarType: DollarType): number {
    const subTotal = progressReportItemsCategorySubTotal(progressReportId, category, budgetType, dollarType)
    const thisYearRevenueSubTotal = progressReportItemsRevenueSubTotal(progressReportId, budgetType, dollarType)

    const projectedSubTotal = progressReportItemsCategoryNetSubTotal(progressReportId, yearlyUpdateId.projected, dollarType)
    switch (budgetType) {
      case yearlyUpdateId.actual:
        return (
          category === BudgetID.revenue
            ? thisYearRevenueSubTotal
            : subTotal
        )
      case yearlyUpdateId.projected:
        return (
          category === BudgetID.revenue
            ? thisYearRevenueSubTotal
            : subTotal
        )
      case yearlyUpdateId.nextReport:
        return (
          category === BudgetID.revenue
            ? projectedSubTotal + thisYearRevenueSubTotal
            : subTotal
        )
      default:
        return 0
    }
  }
  function getTotalsFor(category: string, budgetType: yearlyUpdateId,): number {
    const TdeSubTotal = getCategorySubTotalsFor(category, budgetType, DollarType.TDE)
    const OtherSubTotal = getCategorySubTotalsFor(category, budgetType, DollarType.Other)

    return TdeSubTotal + OtherSubTotal
  }

  function getSubNetFor(budgetType: yearlyUpdateId, dollarType: DollarType): number {
    const rev = getCategorySubTotalsFor(BudgetID.revenue, budgetType, dollarType )
    const direct = getCategorySubTotalsFor(BudgetID.programExpense, budgetType, dollarType)
    const indirect = getCategorySubTotalsFor(BudgetID.indirectExpense, budgetType, dollarType)
    return rev - direct- indirect
  }

  function getNetFor(budgetType: yearlyUpdateId): number {
    const rev = getTotalsFor(BudgetID.revenue, budgetType)
    const direct = getTotalsFor(BudgetID.programExpense, budgetType);
    const indirect = getTotalsFor(BudgetID.indirectExpense, budgetType);
    return rev - direct - indirect
  }
    useEffect(() => {
      console.log('useEffect',warningList);
      setWarningList(warningListCopy)
    }, [warningList]);

    function createTableBody(){
      return (
        <>
          <RevenueExpenseRows isRevenue={true} />
          <RevenueExpenseRows isRevenue={false} />
          <br/>
          <TableRowCat >
            <TableCell>
              <Typography
                variant="h5"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                Net Dollar Excess (deficit):
              </Typography>
            </TableCell>
              {
                currentAndFutureYearlyCats
                  .map((el, index) => {
                    return (createTableCells(getNetFor(el.categoryId), "h6"))
                  })
              }
          </TableRowCat >
          <TableRow>
            <TableCell sx={{ paddingLeft: "5ch" }}>
              <Typography variant="h6" color="primary">
                The Duke Endowment
              </Typography>
            </TableCell>
            {
              currentAndFutureYearlyCats
                .map((el, index) => {
                  return (createTableCells(getSubNetFor(el.categoryId, DollarType.TDE), "h6"))
                })
            }
          </TableRow>
          <TableRow>
            <TableCell sx={{ paddingLeft: "5ch" }}>
              <Typography variant="h6" color="primary">
                Other Sources
              </Typography>
            </TableCell>
            {
              currentAndFutureYearlyCats
                .map((el, index) => {
                  return (createTableCells(getSubNetFor(el.categoryId, DollarType.Other), "h6"))
                })
            }
          </TableRow>          
        </>
      )
    }
    
    return (
      <Box
        component="form"
        sx={{
          // "& .MuiTextField-root": { m: 1, width: "50ch" },
          mx: "auto", 
          width: "90vw",
          
        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h1" sx={{fontSize:"4ch", fontWeight: 400}}>Submit your progress report budget</Typography>
        {/* <Typography variant="subtitle1" sx={{fontSize:"3ch"}}>Progress Report {reportYear}</Typography> */}
        {/* including "Current Budget" */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {currentAndFutureYearlyCats 
                .map((el, index) => {
                  return (
                    <TableCell key={el.categoryId}>
                      <Typography variant="h4" component="h4" align="center" color="primary" sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
                        {el.categoryName}
                      </Typography>
                    </TableCell>
                  )
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {createTableBody()}
          </TableBody>
        </Table>
        { getSubNetFor(yearlyUpdateId.projected, DollarType.TDE) !== 0 &&
            <Alert sx={{mt: "1ch"}} severity="warning">There is a projected surplus or deficit of Duke Endowment Funds. Please review your budget. If this is accurate, please note the reason in the narrative on the previous page or coordinate with your Endowment contact person using the <strong>Contact Us</strong> button on your progress report form.</Alert>
        }
        {warningList.length !== 0 && (
          <Box sx={{ margin: '0 auto' }}>
            {/* <Typography variant="h1" sx={{fontSize:"3ch", fontWeight: 400, mt: "30px", mb: "20px"}}>Please review: </Typography>
            <Warnings warningList = {warningList}/> */}
          </Box>
        )}
      </Box>
    );
  }



function RevenueExpenseRows( props: {isRevenue: boolean}) {
  const {isRevenue} = props;
  const progressReportId = useAppSelector(selectCurrentProgressReport);
  const progressReportItemsCategorySubTotal = useAppSelector(selectProgressReportItemsCategorySubTotal);
  const { revenue, programExpense, indirectExpense } = BudgetID;
  const progressReportItemsRevenueSubTotal = useAppSelector(selectProgressReportItemsRevenueSubTotal)
  const progressReportItemsCategoryNetSubTotal = useAppSelector(selectProgressReportItemsCategoryNetSubTotal)
  const progressReportItemsExpensesSubTotal = useAppSelector(selectProgressReportItemsExpensesSubTotal)


  function getCategorySubTotalsFor(category: string, budgetType: yearlyUpdateId, dollarType: DollarType): number {
    const subTotal = progressReportItemsCategorySubTotal(progressReportId, category, budgetType, dollarType)
    const thisYearRevenueSubTotal = progressReportItemsRevenueSubTotal(progressReportId, budgetType, dollarType)

    const projectedSubTotal = progressReportItemsCategoryNetSubTotal(progressReportId, yearlyUpdateId.projected, dollarType)
    switch (budgetType) {
      case yearlyUpdateId.actual:
        return (
          category === BudgetID.revenue
            ? thisYearRevenueSubTotal
            : subTotal
        )
      case yearlyUpdateId.projected:
        return (
          category === BudgetID.revenue
            ? thisYearRevenueSubTotal
            : subTotal
        )
      case yearlyUpdateId.nextReport: // todo
        return (
          category === BudgetID.revenue
            ? thisYearRevenueSubTotal + projectedSubTotal 
            : subTotal
        )
      default:
        return 0
    }
  }
  function getTotalsFor(category: string, budgetType: yearlyUpdateId,): number {
    const TdeSubTotal = getCategorySubTotalsFor(category, budgetType, DollarType.TDE)
    const OtherSubTotal = getCategorySubTotalsFor(category, budgetType, DollarType.Other)

    return TdeSubTotal + OtherSubTotal
  }

  function getNetFor(budgetType: yearlyUpdateId): number {
    const rev = getTotalsFor(BudgetID.revenue, budgetType)
    const direct = getTotalsFor(BudgetID.programExpense, budgetType);
    const indirect = getTotalsFor(BudgetID.indirectExpense, budgetType);
    return rev - direct - indirect
  }

  return (
    <>
      <TableRowCat >
        <TableCell>
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            {isRevenue ? "Total Revenues:" : "Total Expenses:"}
          </Typography>
        </TableCell>
        {
          currentAndFutureYearlyCats
            .map((el, index) => {
              return (
                createTableCells(
                  isRevenue 
                    ? getTotalsFor(revenue, el.categoryId)
                    : getTotalsFor(programExpense, el.categoryId) + getTotalsFor(indirectExpense, el.categoryId)
                    ,
                  "h5", { fontWeight: "bold" })
              )
            })
        }


      </TableRowCat>
      <TableRow>
        <TableCell sx={{paddingLeft: "5ch"}}>
          <Typography variant="h6" color="primary" >
            The Duke Endowment
          </Typography>
        </TableCell>
        
        {
          currentAndFutureYearlyCats
            .map((el, index) => {
              return (
                createTableCells(
                  isRevenue
                    ? getCategorySubTotalsFor(revenue, el.categoryId, DollarType.TDE)
                    : progressReportItemsExpensesSubTotal(progressReportId, el.categoryId, DollarType.TDE)
                  ,
                  "h6"
                 )

              )
            })
        }
      </TableRow>
      <TableRow>
        <TableCell sx={{paddingLeft: "5ch"}}>
          <Typography variant="h6" color="primary">
            Other Sources
          </Typography>
        </TableCell>
        {
          currentAndFutureYearlyCats
            .map((el, index) => {
              return (
                createTableCells(
                  isRevenue
                    ? getCategorySubTotalsFor(revenue, el.categoryId, DollarType.Other)
                    : progressReportItemsExpensesSubTotal(progressReportId, el.categoryId, DollarType.Other)
                  ,
                  "h6"
                )

              )
            })
        }
      </TableRow>

      { isRevenue 
        ?<TableRow>
          <TableCell sx={{paddingLeft: "5ch"}}>
            <Typography variant="h6" color="primary">
            Carryforward (net dollar excess)
            </Typography>
          </TableCell>
          <TableCell/>
          <TableCell/>
          {createTableCells(getNetFor(yearlyUpdateId.projected), "h6")}
        </TableRow>
        : null
      }
    </>
  )

}
// function ExpenseRows(props:{expenseType: BudgetType}) {
//   const {expenseType} = props;
//   const { id, name, groupName, altGroupName} = expenseType;
//   const currentProgressReport = useAppSelector(selectCurrentProgressReport);
//   const progressReportItemsCategoryTotal = useAppSelector(selectProgressReportItemsCategoryTotal);
//   const progressReportItemsCategorySubTotal = useAppSelector(selectProgressReportItemsCategorySubTotal);
//   const currentBudgetCategoryTotal = useAppSelector(selectCurrentBudgetCategoryTotal)
//   const { revenue, programExpense, indirectExpense } = BudgetID;

//   function createTableCells(dollarVal, variant, cellStyle = {}  ){
//     return(
//             <TableCell>
//               <Typography
//                 variant={variant}
//                 color="primary"
//                 textAlign={'end'}
//                 sx={cellStyle}
//               >
//                 {formatDollarValues(dollarVal)}
//               </Typography>
//             </TableCell>
//         )
//   }

//   return (
//     <>
//       <TableRowCat >
//         <TableCell>
//           <Typography
//             variant="h5"
//             color="primary"
//             sx={{ fontWeight: "bold" }}
//           >
//             Total {groupName}:
//           </Typography>
//         </TableCell>
//         {
//           currentAndFutureYearlyCats 
//                 .map((el, index) => {
//                   return (
//                     createTableCells(progressReportItemsCategoryTotal(currentProgressReport, revenue, el.categoryId),
//                     "h5", {fontWeight: "bold"})
//                   )
//                 })
//         }

//       </TableRowCat>
//       <TableRow>
//         <TableCell sx={{paddingLeft: "5ch"}}>
//           <Typography variant="h6" color="primary">
//             The Duke Endowment
//           </Typography>
//         </TableCell>
//         {
//           currentAndFutureYearlyCats
//             .map((el, index) => {
//               return (
//                 createTableCells(
//                   progressReportItemsCategorySubTotal(currentProgressReport, revenue, el.categoryId, DollarType.TDE),
//                   "h6"
//                  )

//               )
//             })
//         }
//       </TableRow>
//       <TableRow>
//         <TableCell sx={{paddingLeft: "5ch"}}>
//           <Typography variant="h6" color="primary">
//             Other Sources
//           </Typography>
//         </TableCell>
//         {
//           currentAndFutureYearlyCats
//             .map((el, index) => {
//               return (
//                 createTableCells(
//                   progressReportItemsCategorySubTotal(currentProgressReport, revenue, el.categoryId, DollarType.Other),
//                   "h6"
//                 )

//               )
//             })
//         }
//       </TableRow>
//     </>
//   )
// }

function Warnings(props: {warningList: warningItem[]}){
  const {warningList} = props;
  console.log(warningList);
  return (
    <>
      {warningList.map((el,i)  => {
        console.log(el.netAmount)
        return(
          <React.Fragment key={i}>
            <Typography variant="h1"  sx={{fontSize:"3ch", fontWeight: 400}}>
              {el.categoryName};
            </Typography>
            <ul>
              {
                (el.netAmount < 0 ) && 
                <li style={{fontSize: '22px'}} >
                  The reported revenue is lower than the expenses. Please review your budget or click 'back' to go to the progress report page to add a narrative.
                </li>
              }
              {
//carryOver under development
                (el.carryOver!==null && el.carryOver > el.totalRevenue * 0.15) &&
                <li style={{fontSize: '22px'}}>
                  Your carryover is over 15% of your expected budget. Please review your budget or click 'back' to go to the progress report page to add a narrative.
                </li>
              }
            </ul>
            {/* <Box sx={ {pl: "30px"  }}>
              {
                (el.netAmount < 0 ) && 
                <Typography gutterBottom variant="h6" component="div" color="primary" sx={{marginBottom: 5}}>
                  The reported revenue is lower than the expenses. Please review your budget or click 'back' to go to the progress report page to add a narrative.
                </Typography>
              }
              {
//carryOver under development
                (el.carryOver!==null && el.carryOver > el.totalRevenue * 0.15) &&
                <Typography gutterBottom variant="h6" component="div" color="primary" sx={{marginBottom: 5}}>
                  Your carryover is over 15% of your expected budget. Please review your budget or click 'back' to go to the progress report page to add a narrative.
                </Typography>
              }
            </Box> */}
          </React.Fragment>
        )
      })}
    </>
  )
}
function createTableCells(dollarVal, variant, cellStyle = {}) {
  return (
    <TableCell>
      <Typography
        variant={variant}
        color="primary"
        textAlign={'end'}
        sx={cellStyle}
      >
        {formatDollarValues(dollarVal)}
      </Typography>
    </TableCell>
  )
}


