import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { styled, Theme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";

import "./Layout.css";
import { msalInternalConfig } from "../../authInternalConfig";

const drawerWidth = 240;

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "30ch",
      },
    },
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
  // @ts-ignore
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export function Layout() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [lookup, setLookup] = React.useState("Lookup");
  const [lookupId, setLookupId] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { instance, accounts } = useMsal();
  console.log(accounts[0].username);

  const signOutClickHandler = (instance) => {
    instance.logoutRedirect(msalInternalConfig.auth);
  }
  const onLookup = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLElement>
  ) => {
    if (event.key === "Enter" && lookupId) {
      event.preventDefault();
      setLookupId("");
      event.currentTarget.blur();
      navigate(`/budget/application/${lookupId}`);
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* @ts-ignore */}
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
            sx={{
              ...(!open && { display: "none" }),
            }}
          >
            <MenuOpenIcon />
          </IconButton> */}
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Staff Budget Portal
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              onFocus={() => setLookup("Enter Budget Application ID")}
              onBlur={() => setLookup("Lookup")}
              onKeyDown={onLookup}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLookupId(event.target.value);
              }}
              placeholder={lookup}
              value={lookupId || ""}
              inputProps={{ "aria-label": "lookup" }}
            />
          </Search>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to={"/account"}>
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>My account</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => signOutClickHandler(instance)}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Sign out</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {/* <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ height: { xs: 56, md: 64 } }}>
          <Link to="/">
            <img
              style={{ height: 60, marginTop: 8 }}
              src="/logo.png"
              alt="The Duke Endowment"
            />
          </Link>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem button key={"Dashboard"} component={Link} to={"/"}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </List>
        <Divider />
        <List
          component="nav"
          aria-labelledby="applications-subheader"
          subheader={
            <ListSubheader component="div" id="applications-subheader">
              Grant Budgets
            </ListSubheader>
          }
        >
          <ListItem
            button
            key={"Applications"}
            component={Link}
            to={"/budget/application"}
          >
            <ListItemIcon>
              <MoveToInboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Applications"} />
          </ListItem>
          <ListItem
            button
            key={"requirements"}
            component={Link}
            to={"/budget/requirement"}
          >
            <ListItemIcon>
              <EventRepeatIcon />
            </ListItemIcon>
            <ListItemText primary={"Requirements"} />
          </ListItem>
        </List>
        <Divider />
        <List
          component="nav"
          aria-labelledby="administration-subheader"
          subheader={
            <ListSubheader component="div" id="administration-subheader">
              Administration
            </ListSubheader>
          }
        >
          <ListItem
            button
            key={"Manage Accounts"}
            component={Link}
            to={"/admin/accounts"}
          >
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary={"Manage Accounts"} />
          </ListItem>
          <ListItem
            button
            key={"Settings"}
            component={Link}
            to={"/admin/settings"}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={"Settings"} />
          </ListItem>
        </List>
      </Drawer> */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* An <Outlet> renders whatever child route is currently active,
        so you can think about this <Outlet> as a placeholder for
        the child routes we defined above. */}
        <Outlet />
      </Box>
    </Box>
  );
}
