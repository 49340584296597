import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { ProgressReportCategoryRow } from "./ProgressReportCategoryRow";
import {
  BudgetID,
  AvailableBudgetTypes,
  yearlyUpdateCategories,
  yearlyUpdateId,
  dateTerms
} from "../../../../app/models";
import {
  setDefinitionHelpers,
  setPrintScreenOpen,
  selectPrintScreenOpen
} from '../../../Common/stateSlices/appStateSlice';
import {
  selectBeginingOrEndingDateString,
} from '../../../Common/stateSlices/bbgDataSlice';
import {
  selectExpenseTypesAvailable,
  selectIsFirstProgressReport,
  selectCurrentBudgetCategoryTotal,
  selectProgressReportItemsExpensesTotal,
  selectProgressReportItemsRevenueTotal2,
  selectProgressReportItemsCategoryNetTotal2,
  selectPreviousProgressReportId,
} from '../../../Common/stateSlices/budgetStateSlice';

import { formatDollarValues} from "../../../Common/helpers";
import { TableCellBorderLR, TableCellBorderL, TableCellBorderR } from "../../../Common/ThemedMuiComponents";
import { Button } from "@mui/material";
import  Print  from "@mui/icons-material/Print";


export const ProgressReportTable = (props: {
  progressReportId: any,
  disabled: boolean
}) => {
  const {progressReportId, disabled} = props;
console.log(progressReportId)
  const { hasProgramExpenses, hasIndirectExpenses } = useAppSelector(selectExpenseTypesAvailable)
  const budgetCategories = AvailableBudgetTypes({ hasProgramExpenses, hasIndirectExpenses, hasInKindExpenses: false })
  const beginningOrEndingDateString = useAppSelector(selectBeginingOrEndingDateString);
  const currentBudgetCategoryTotal = useAppSelector(selectCurrentBudgetCategoryTotal)
  const isFirstProgressReportSelector = useAppSelector(selectIsFirstProgressReport);
  const isFirstProgressReport = isFirstProgressReportSelector(progressReportId) // defines if first progress report, used to grab either previous progress report or application budget data
  const progressReportItemsExpensesTotal = useAppSelector(selectProgressReportItemsExpensesTotal)
  const previousProgressReportId = useAppSelector(selectPreviousProgressReportId);
  const printScreenOpen = useAppSelector(selectPrintScreenOpen);
  const previousReport = previousProgressReportId(progressReportId)
  const { programExpense, indirectExpense} = BudgetID;
  const progressReportItemsRevenueTotal2 = useAppSelector(selectProgressReportItemsRevenueTotal2)
  const progressReportItemsCategoryNetTotal2 = useAppSelector(selectProgressReportItemsCategoryNetTotal2)

  const dispatch = useAppDispatch();
  const handleDefClickOpen = (helperKey: string = "giftInKind") => dispatch(setDefinitionHelpers({ visible: true, key: helperKey }))

  function handlePrintScreenOpen(){
    dispatch(setPrintScreenOpen({id: progressReportId, reqType: "Progress Report" }))
  }

  return (
    <TableContainer sx={{ mx: "auto", width: "calc(100vw*.9)", display: 'flex', flexDirection: 'column' }}>
      { !printScreenOpen &&     
        <>
        <Button
          endIcon={<Print />}
          variant="text"
          onClick={handlePrintScreenOpen}
          sx={{alignSelf: "end", width: "170px"}}
        >
          Print PDF
        </Button><br/>
        </>}
      <Table aria-label="collapsible table">
        <TableHead>
{/* 1st Row Current Budget to Next Year's Budget */}
          <TableRow>
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell
              sx={{ borderBottom: "0px solid", width: "35ch" }}
            ></TableCell>
            {yearlyUpdateCategories.map((el, index) => {
            return(
              <React.Fragment key={el.categoryId}>
                <TableCellBorderLR
                  sx={{
                    borderBottom: "0px solid",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    fontSize: "1.1rem",
                    paddingY: "4px"
                  }}
                  align="center"
                  colSpan={2}
                >
                  {el.categoryName}
                  {!printScreenOpen &&<IconButton 
                      size="small" 
                      sx={{ position: 'relative', top: '-8px', padding: 0 }}
                      onClick={() => {handleDefClickOpen(el.categoryId)
                      }}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  }
                    <br/>
                  <Typography sx={{textTransform:"none"}} variant="subtitle1" fontSize="small">
                    {beginningOrEndingDateString(progressReportId, el.categoryId, dateTerms.beginning)} - {beginningOrEndingDateString(progressReportId, el.categoryId, dateTerms.ending)}
                  </Typography>
                </TableCellBorderLR>
              </React.Fragment> 
            )})}
          </TableRow>
{/* 2nd Row: TDE & Other */}
          <TableRow>
            <TableCell />
            <TableCell sx={{ width: "35ch" }} />
            {yearlyUpdateCategories.map((el, index) => {
              return (
                <React.Fragment key={el.categoryId}>
                  <TableCellBorderL 
                    key={el.categoryId + "TDE"} 
                    align="center"
                    sx={{paddingY: '1ch'}}
                    >
                    <Typography 
                      variant="subtitle1" 
                      sx={{ 
                        fontWeight: "bold", 
                        display: "inline-flex", 
                        verticalAlign: "middle"
                      }}
                    >
                      TDE
                      {
                        index === 0 && !printScreenOpen
                          ? <IconButton
                            size="small"
                            sx={{ position: 'relative', padding: 0, paddingLeft: '3px' }}
                            id="test"
                            onClick={() => handleDefClickOpen('TDERevenueExpense')}
                          >
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                          : null
                      }
                    </Typography>
                  </TableCellBorderL>
                  <TableCellBorderR key={index + "Other"} align="center" sx={{ paddingY: '0.7ch' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        display: "inline-flex",
                        verticalAlign: "middle"
                      }}
                    >
                      Other
                      {
                        index === 0 && !printScreenOpen
                          ? <IconButton
                            size="small"
                            sx={{ position: 'relative', paddingLeft: '3px'}}
                            id="test"
                            onClick={() => handleDefClickOpen('OtherRevenueExpense')}
                          >
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                          : null
                      }
                    </Typography>
                  </TableCellBorderR>
                </React.Fragment>
              );
            })}

          </TableRow>
        </TableHead>
        <TableBody>
{/* table items */}
          {budgetCategories
            .map((row) => (
              <ProgressReportCategoryRow
                key={row.id}
                row={row}
                progressReportId={progressReportId}
                disabled={disabled}
              />
            ))}
          <TableRow> 
            <TableCell sx={{ padding: "5px" }} />
          </TableRow> 
{/* Revenue Totals Row */}
          <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)", borderTop: "2px solid rgba(0,0,0,.3)"}}>
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell
              style={{ borderBottom: "0px solid", fontSize: ".95rem"}}
              sx={{ width: "35ch" }}
            >
              Total Revenue
            </TableCell>
            {yearlyUpdateCategories.map((el, index)  => {
              return (
                <React.Fragment key={el.categoryId}>
                  <TableCellBorderLR
                    key={el.categoryId}
                    style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                    align="center"
                    colSpan={2}
                  >
                    {formatDollarValues( progressReportItemsRevenueTotal2(progressReportId, el.categoryId))}
                  </TableCellBorderLR>
                </React.Fragment>
              );
            })}
          </TableRow>
{/* Total Expenses Row */}
          <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)"}}> 
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell
              style={{ borderBottom: "0px solid", fontSize:".95rem" }}
              sx={{ width: "35ch" }}
            >
              Total Expenses
            </TableCell>
            {yearlyUpdateCategories.map((el, index) => {
              return (
                <React.Fragment key={el.categoryId}>
                  <TableCellBorderLR
                    key={el.categoryId}
                    style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                    align="center"
                    colSpan={2}
                  >
                    {
                      formatDollarValues(el.categoryId === yearlyUpdateId.current
                        ? isFirstProgressReport
                          ? currentBudgetCategoryTotal(progressReportId, programExpense) + currentBudgetCategoryTotal(progressReportId, indirectExpense)
                          : progressReportItemsExpensesTotal(previousReport, yearlyUpdateId.nextReport)
                        : progressReportItemsExpensesTotal(progressReportId, el.categoryId) 
                      )
                    }

                  </TableCellBorderLR>
                </React.Fragment>
              );
            })}
          </TableRow>
{/* Net Dollar Excess (Deficit) Row */}
          <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)"}} style={{ border: "0px solid", borderBottom: "2px solid rgba(0,0,0,.3)" }}>
              <TableCell sx={{ borderBottom: "0px solid" }} />
              <TableCell
                sx={{
                  borderBottom: "0px solid",
                  width: "35ch"
                }}
              >
                Net Dollar Excess (Deficit)
              </TableCell>
              {yearlyUpdateCategories.map((el) => {
                const netTotal = progressReportItemsCategoryNetTotal2(progressReportId, el.categoryId)
                const formattedNetTotal = formatDollarValues(netTotal)
                return (
                  <React.Fragment key={el.categoryId}>
                    <TableCellBorderLR
                      key={el.categoryId}
                      sx={{ borderBottom: "0px solid", 
                      fontWeight: "bold", 
                        color: (netTotal <0) ? "red" : "black" 
                      }}
                      align="center"
                      colSpan={2}
                    >
                      {formattedNetTotal}
                    </TableCellBorderLR>
                  </React.Fragment>
                );
              })}
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};


